import React from 'react';

export function DisplayMarkup(props: {
  markup: string | null;
  className?: string;
}) {
  const createMarkup = (__html: string) => {
    return { __html };
  };

  if (props.markup) {
    return (
      <div
        className={props.className}
        dangerouslySetInnerHTML={createMarkup(props.markup)}
      />
    );
  } else {
    return null;
  }
}
