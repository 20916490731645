import React, { Dispatch } from 'react';
import { EventData } from '../types';

// State
type AppState = {
  drawerOpen: boolean;
  eventsLoading: boolean;
  eventsData: EventData[];
};
const initialState: AppState = {
  drawerOpen: false,
  eventsLoading: true,
  eventsData: [],
};

// Actions
type AppActions =
  | { type: 'TOGGLE_DRAWER' }
  | { type: 'DRAWER_CLOSE' }
  | { type: 'SET_EVENTS'; payload: EventData[] };

const reducer = (state: AppState, action: AppActions) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER': {
      return { ...state, drawerOpen: !state.drawerOpen };
    }
    case 'DRAWER_CLOSE': {
      return { ...state, drawerOpen: false };
    }
    case 'SET_EVENTS': {
      return { ...state, eventsLoading: false, eventsData: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export const AppContext = React.createContext(
  {} as {
    state: AppState;
    dispatch: Dispatch<AppActions>;
  }
);

export function AppProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
}
