import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import React from 'react';
import { ReactComponent as AllianceLogo } from '../assets/alliance-logo.svg';
import { AuthContext } from '../contexts/auth-context';

export function TempMaintenanceMessage() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [visible, setVisible] = React.useState(true);

  const isAuthenticated = state.user !== undefined;

  if (visible) {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 3,
        }}
      >
        <Container maxWidth="sm" sx={{ p: 1 }}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <Stack spacing={1} direction="row" alignItems="center">
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => setVisible(false)}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Dismiss
                </Button>

                {isAuthenticated ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => Auth.signOut()}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Sign out
                  </Button>
                ) : null}
              </Stack>
            }
          >
            {/* <span>Maintenance is being performed until {mEnd}</span> */}
            <span>Maintenance is being performed</span>
          </Alert>
        </Container>
      </Box>
    );
  } else {
    return null;
  }
}

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    backgroundColor: '#212121',
    height: 140,
    width: 140,
    borderRadius: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
});

export function MaintenanceMessage(props: { maintenanceEnd: string }) {
  // Props
  // const { maintenanceEnd } = props;
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const classes = useStyles();

  // let mEnd = '';
  // if (maintenanceEnd !== '' && isValid(new Date(maintenanceEnd))) {
  //   mEnd = format(new Date(maintenanceEnd), 'PPPPpppp');
  // }

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <AllianceLogo style={{ height: 48 }} />
      </div>

      <Typography variant="h3" align="center" gutterBottom>
        Under Maintenance
      </Typography>

      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ color: '#4a4a4a' }}>
          IT Maintenance Update:
        </Typography>
        <Typography>
          We are currently experiencing systems issues. We are working hard to
          fix the issues and hope to be back online fully soon.
        </Typography>
      </Container>

      {/* {mEnd !== '' ? (
        <Typography align="center" gutterBottom>
          Please check back on {mEnd}
        </Typography>
      ) : null} */}

      {state.user !== undefined ? (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ marginTop: 10 }}
          onClick={() => Auth.signOut()}
        >
          Sign out
        </Button>
      ) : null}
    </div>
  );
}
