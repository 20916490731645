import React from 'react';

export function CustomerProfileIframe(props: {
  action:
    | '/customer/manage'
    | '/customer/addPayment'
    | '/customer/addShipping'
    | '/customer/editPayment'
    | '/customer/editShipping';
  iframeToken: string;
  title: string;
  paymentProfileId?: string;
  shippingAddressId?: string;
  onReceiveCommunication: (action: 'cancel' | 'successfulSave') => void;
}) {
  // Ref
  const formEl = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    (window as any).CommunicationHandler = {};
    (window as any).CommunicationHandler.onReceiveCommunication = (data: {
      qstr: string;
      parent: string;
    }) => {
      if (data.qstr && data.qstr === 'action=cancel') {
        props.onReceiveCommunication('cancel');
      } else if (data.qstr && data.qstr === 'action=successfulSave') {
        props.onReceiveCommunication('successfulSave');
      }
    };
  }, [props]);

  React.useEffect(() => {
    if (formEl && formEl.current) {
      formEl.current.submit();
    }
  }, [formEl]);

  const action = process.env.REACT_APP_ACCEPT_PROFILES + props.action;

  return (
    <div style={{ minHeight: 600, width: '100%' }}>
      <iframe
        className="embed-responsive-item"
        frameBorder="0"
        height={600}
        id="load_profile"
        name="load_profile"
        title={props.title}
        width="100%"
      />

      <form
        action={action}
        id="send_token"
        method="post"
        ref={formEl}
        target="load_profile"
      >
        <input name="token" type="hidden" value={props.iframeToken} />

        {props.paymentProfileId ? (
          <input
            name="paymentProfileId"
            type="hidden"
            value={props.paymentProfileId}
          />
        ) : null}

        {props.shippingAddressId ? (
          <input
            name="shippingAddressId"
            type="hidden"
            value={props.shippingAddressId}
          />
        ) : null}
      </form>
    </div>
  );
}
