import Close from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowWidth } from '@react-hook/window-size';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import isEmail from 'validator/lib/isEmail';
import { AnimateIn } from '../../components/animate-in';
import { CardIcon } from '../../components/card-icon';
import {
  CustomerData,
  CustomerPaymentData,
  EventRegistrationData,
  EventTicketData,
  TicketData,
} from '../../types';
import { signIn } from '../../utils/authenticate';
import { captureError } from '../../utils/capture-error';
import { toCurrency } from '../../utils/formatter';
import { RegisterConfirmation } from './register-confirmation';
import { RegisterPaymentAdd } from './register-payment-add';
import { RegisterTermsAccept } from './register-terms-accept';
import { RegisterTicketSelect } from './register-ticket-select';

const useSyles = makeStyles((theme) => ({
  payments: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
}));

export function RegisterGuest(props: { data: EventRegistrationData }) {
  // State
  const [open, setOpen] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [register, setRegister] = React.useState({
    customerProfileId: '',
    customerPaymentProfileId: '',
    EventId: 0,
    TicketTypeId: 0,
    ticketCount: '1',
    OwnerFirstName: '',
    OwnerLastName: '',
    OwnerEmail: '',
  });
  const [assignment, setAssignment] = React.useState({
    AttendeeTypeId: 'Guest',
    DisplayFirstName: '',
    DisplayLastName: '',
    DisplayEmail: '',
  });
  const [ticket, setTicket] = React.useState<EventTicketData>();
  const [hasAgreed, setHasAgreed] = React.useState(false);
  const [purchaseComplete, setPurchaseComplete] = React.useState(false);
  const [purchaseError, setPurchaseError] = React.useState(false);
  const [purchaseErrorMsg, setPurchaseErrorMsg] = React.useState('');
  const [ticketsPurchased, setTicketsPurchased] = React.useState<TicketData[]>(
    [],
  );
  const [billing, setBilling] = React.useState<CustomerData>();
  // Hooks
  const width = useWindowWidth();
  const classes = useSyles();

  React.useEffect(() => {
    const ticketTypes = orderBy(props.data.TicketTypes, 'Price');
    if (ticketTypes.length) {
      // Default to paying full price for the cheapest ticket
      const ticketType = ticketTypes[0];
      setRegister((currentState) => ({
        ...currentState,
        EventId: props.data.EventId,
        TicketTypeId: ticketType.TicketTypeId,
      }));
      setTicket(ticketType);
    }
  }, [props.data]);

  const handlePurchase = async () => {
    try {
      setSubmitting(true);
      setPurchaseError(false);
      setPurchaseErrorMsg('');

      const response: {
        data: TicketData[];
      } = await API.post('EventsAPI', '/events/tickets/purchase/non-agent', {
        body: {
          ...register,
          ticketAssignments: [assignment],
        },
      });
      if (response.data.length) {
        setTicketsPurchased(response.data);
      }
      setPurchaseComplete(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error?.response?.data) {
        const errData = error.response.data;
        if (errData?.status === 'invalid') {
          if (errData?.errors?.declined) {
            setPurchaseErrorMsg(errData.errors.declined);
          } else if (errData?.errors?.ticketCount) {
            setPurchaseErrorMsg(errData?.errors?.ticketCount[0]);
          } else if (errData?.error?.includes('400')) {
            setPurchaseErrorMsg('The transaction was unsuccessful.');
          }
        }
      }
      captureError({ data: { error, ticket } });
      setPurchaseError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  const ticketIsFree: boolean = Boolean(
    ticket && ticket.Price !== null && ticket.Price === 0,
  );

  let paymentProfile: CustomerPaymentData | undefined = undefined;
  if (billing && billing.customerPaymentProfiles.length) {
    paymentProfile = billing.customerPaymentProfiles.find(
      (i) => i.customerPaymentProfileId === register.customerPaymentProfileId,
    );
  }

  let payTodayMax = 0;
  if (ticket && register.ticketCount) {
    payTodayMax = ticket.Price
      ? ticket.Price * Number(register.ticketCount)
      : 0;
  }

  const isDesktop = width > 1024;

  const steps: { label: string; content: (index: number) => JSX.Element }[] = [
    {
      label: 'Select Ticket',
      content: (index) => {
        const nextDisabled: boolean =
          !register.TicketTypeId ||
          register.ticketCount === '0' ||
          !isEmail(register.OwnerEmail) ||
          !register.OwnerFirstName ||
          !register.OwnerLastName ||
          !isEmail(assignment.DisplayEmail) ||
          !assignment.DisplayFirstName ||
          !assignment.DisplayLastName;

        return (
          <div>
            <RegisterTicketSelect
              data={props.data}
              TicketTypeId={register.TicketTypeId}
              onSelect={(ticket) => {
                const { TicketTypeId } = ticket;
                // Update selected ticket
                setRegister((currentState) => ({
                  ...currentState,
                  TicketTypeId,
                }));
                setTicket(ticket);
              }}
            />

            <div style={{ padding: 10 }}>
              <Typography variant="h6" component="div" gutterBottom>
                Purchaser Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    name="OwnerFirstName"
                    value={register.OwnerFirstName}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setRegister((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    name="OwnerLastName"
                    value={register.OwnerLastName}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setRegister((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email Address"
                    name="OwnerEmail"
                    type="email"
                    value={register.OwnerEmail}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setRegister((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{ padding: 10 }}>
              <Typography variant="h6" component="div" gutterBottom>
                Attendee Info
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="register-guest-type-label">
                      Registration Type
                    </InputLabel>
                    <Select
                      labelId="register-guest-type-label"
                      id="register-guest-type"
                      value={assignment.AttendeeTypeId}
                      label="Registration Type"
                      onChange={(event) => {
                        const registrationType = event.target.value as string;
                        setAssignment((currentState) => ({
                          ...currentState,
                          AttendeeTypeId: registrationType,
                        }));
                      }}
                    >
                      {['Guest', 'Carrier', 'Vendor'].map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="First Name"
                    name="DisplayFirstName"
                    value={assignment.DisplayFirstName}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setAssignment((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Last Name"
                    name="DisplayLastName"
                    value={assignment.DisplayLastName}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setAssignment((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email Address"
                    name="DisplayEmail"
                    type="email"
                    value={assignment.DisplayEmail}
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setAssignment((currentState) => ({
                        ...currentState,
                        [name]: value,
                      }));
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 10,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={nextDisabled}
                onClick={() => {
                  setActiveStep(index + 1);
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Review Terms',
      content: (index) => {
        const nextDisabled: boolean = !hasAgreed;

        return (
          <div>
            <RegisterTermsAccept
              data={props.data}
              accepted={hasAgreed}
              onChange={(checked) => setHasAgreed(checked)}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveStep(index - 1);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={nextDisabled}
                onClick={() => {
                  setActiveStep(index + 1);
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Complete Purchase',
      content: (index) => {
        return (
          <div>
            <div>
              <Typography variant="h6" component="div" gutterBottom>
                Total
              </Typography>

              <AnimateIn to="bottom">
                <div style={{ padding: 10 }}>
                  {ticket ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ fontSize: 18 }}>
                        {register.ticketCount} x {ticket.Title} at{' '}
                        {toCurrency({ value: ticket.Price })}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: 1,
                          backgroundColor: '#00000033',
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />

                      <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                        {toCurrency({ value: payTodayMax })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </AnimateIn>

              {ticketIsFree ? null : (
                <React.Fragment>
                  <Typography variant="h6" component="div" gutterBottom>
                    Payment
                  </Typography>

                  <div className={classes.payments}>
                    <div style={{ flex: 1 }}>
                      <RegisterPaymentAdd
                        email={register.OwnerEmail}
                        onUpdateBilling={(newBilling, newProfileId) => {
                          if (
                            newBilling &&
                            newBilling.customerPaymentProfiles.length
                          ) {
                            setBilling(newBilling);
                            const { customerPaymentProfileId } =
                              newBilling.customerPaymentProfiles[0];
                            setRegister((currentState) => ({
                              ...currentState,
                              customerProfileId:
                                newBilling.customerProfileId || '',
                              customerPaymentProfileId:
                                customerPaymentProfileId || '',
                            }));
                          }
                        }}
                      />
                    </div>

                    {paymentProfile ? (
                      <div style={{ flex: 1, margin: 10 }}>
                        <div
                          style={{
                            height: 240,
                            position: 'relative',
                            backgroundColor: '#fff',
                            border: '1px solid #4a4a4a88',
                            display: 'flex',
                            borderRadius: 20,
                            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: 'rgb(239, 239, 239)',
                              borderRadius: '20px 0 0 20px',
                              borderRight: '1px solid #00000044',
                            }}
                          >
                            <div style={{ textAlign: 'center' }}>
                              <CardIcon
                                cardType={paymentProfile.cardType || ''}
                                size="6x"
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              flex: 2,
                              display: 'flex',
                              alignItems: 'center',
                              padding: 10,
                            }}
                          >
                            <div>
                              <strong
                                style={{
                                  fontSize: 16,
                                  fontFamily: 'Oswald',
                                  letterSpacing: 1.1,
                                }}
                              >
                                {paymentProfile.cardType?.toUpperCase()}
                              </strong>
                              <p>
                                {paymentProfile.billToFirstName}{' '}
                                {paymentProfile.billToLastName}
                              </p>
                              <div>
                                <span style={{ fontFamily: 'monospace' }}>
                                  **** **** ****{' '}
                                  {paymentProfile.cardNumber?.replaceAll(
                                    'X',
                                    '',
                                  )}
                                </span>
                              </div>
                              <div style={{ marginTop: 10 }}>
                                <span style={{ fontFamily: 'monospace' }}>
                                  EXP: {paymentProfile.expirationDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              )}
            </div>

            {purchaseError ? (
              <div style={{ padding: 10 }}>
                <Alert severity="error" onClose={() => setPurchaseError(false)}>
                  <strong style={{ marginRight: 10 }}>
                    An error has occurred!
                  </strong>
                  {purchaseErrorMsg ? (
                    <small>{purchaseErrorMsg}</small>
                  ) : (
                    <small>Please refresh the page and try again.</small>
                  )}
                </Alert>
              </div>
            ) : null}

            <div
              style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                disabled={submitting}
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={() => setActiveStep(index - 1)}
              >
                Back
              </Button>

              {ticketIsFree ? (
                <Button
                  disabled={submitting || purchaseError}
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={handlePurchase}
                >
                  {submitting
                    ? 'Registering...'
                    : `Register ${register.ticketCount} ticket(s)`}
                </Button>
              ) : (
                <Button
                  disabled={
                    submitting ||
                    purchaseError ||
                    !billing ||
                    !register.customerPaymentProfileId
                  }
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={handlePurchase}
                >
                  {submitting
                    ? 'Purchasing...'
                    : `Confirm Purchase ${toCurrency({ value: payTodayMax })}`}
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  if (purchaseComplete) {
    return (
      <RegisterConfirmation
        data={props.data}
        billing={billing}
        paymentProfileId={register.customerPaymentProfileId}
        ticketData={ticket}
        ticketCount={register.ticketCount}
        ticketsPurchased={ticketsPurchased}
        OwnerEmail={register.OwnerEmail}
      />
    );
  } else {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <DialogContent>
            <DialogTitle>
              <IconButton
                size="small"
                style={{ position: 'absolute', top: 10, right: 10 }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <div
              style={{
                marginBottom: 5,
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: 18,
              }}
            >
              You are checking out as a <strong>guest</strong>.<br />
              If you are an Agent make sure to log in.
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSignIn}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>

        <Stepper
          activeStep={activeStep}
          orientation={isDesktop ? 'horizontal' : 'vertical'}
          sx={{ p: 2 }}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              {isDesktop ? null : (
                <StepContent>{step.content(index)}</StepContent>
              )}
            </Step>
          ))}
        </Stepper>

        {isDesktop ? (
          <div style={{ backgroundColor: '#fff', padding: 20 }}>
            {steps.map((step, index) => {
              if (activeStep === index) {
                return <div key={step.label}>{step.content(index)}</div>;
              } else {
                return null;
              }
            })}
          </div>
        ) : null}
      </div>
    );
  }
}
