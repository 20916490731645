import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowWidth } from '@react-hook/window-size';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AllianceLogo } from '../../assets/alliance-logo.svg';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { signIn } from '../../utils/authenticate';
import { captureError } from '../../utils/capture-error';
import { navData } from './nav-data';

const useStyles = makeStyles({
  navButton: {
    color: '#fff',
    border: '1px solid #ffffff33',
    minHeight: 50,
    borderRadius: 14,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export function NavBar() {
  // Context
  const appContext = React.useContext(AppContext);
  const { state } = React.useContext(AuthContext);
  // Hooks
  const width = useWindowWidth();
  const classes = useStyles();
  const { checkPermissions } = usePermissions();

  const queryClient = useQueryClient();
  const handleSignOut = async () => {
    // Remove any cached data
    queryClient.removeQueries();
    // Sign out current user from Cognito
    await Auth.signOut();
    // After being signed out from Cognito
    // Hub.listen > 'auth' > 'signOut' will remove user  data from Context
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  const signInButton = (
    <ButtonBase
      focusRipple
      className={classes.navButton}
      onClick={handleSignIn}
    >
      <Typography style={{ fontSize: 18 }}>Sign in</Typography>
    </ButtonBase>
  );

  const isDesktop = width > 1024;

  // This empty toolbar is used to offset content
  // Otherwise content would be hidden behind the main AppBar
  const offsetToolbar = <Toolbar />;

  if (isDesktop) {
    return (
      <React.Fragment>
        <AppBar elevation={0} style={{ backgroundColor: '#212121' }}>
          <Toolbar>
            <div style={{ flex: 1 }}>
              <Link
                to="/"
                aria-label="home"
                style={{ display: 'inline-block' }}
              >
                <AllianceLogo style={{ height: 44 }} />
              </Link>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {navData.map((item) => {
                let canView = true;
                if (item.authenticated) {
                  if (item.permissions) {
                    canView = checkPermissions(item.permissions);
                  } else {
                    canView = state.user !== undefined;
                  }
                }

                if (canView) {
                  return (
                    <Button
                      key={item.to}
                      component={Link}
                      to={item.to}
                      size="large"
                      style={{
                        color: '#fff',
                        margin: 5,
                        fontFamily: 'Oswald',
                        fontSize: 18,
                      }}
                    >
                      {item.title}
                    </Button>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            <div
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              {state.user !== undefined ? (
                <ButtonBase
                  focusRipple
                  className={classes.navButton}
                  onClick={handleSignOut}
                >
                  <Typography style={{ fontSize: 18 }}>
                    Logout {state.user.getUsername()}
                  </Typography>
                </ButtonBase>
              ) : (
                signInButton
              )}
            </div>
          </Toolbar>
        </AppBar>

        {offsetToolbar}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <AppBar elevation={0} style={{ backgroundColor: '#212121' }}>
          <Toolbar>
            <Link to="/" aria-label="home">
              <AllianceLogo style={{ height: 36 }} />
            </Link>

            <div style={{ flex: 1 }} />

            {state.user === undefined ? (
              <div style={{ marginRight: 30 }}>{signInButton}</div>
            ) : null}

            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              style={{ color: '#fff', border: '1px solid #ffffff33' }}
              onClick={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {offsetToolbar}
      </React.Fragment>
    );
  }
}
