import addMonths from 'date-fns/addMonths';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import startOfMonth from 'date-fns/startOfMonth';

/**
 * Get the date for the 1st of the next month.
 */
export function getNextPayDate() {
  return startOfMonth(addMonths(new Date(), 1));
}

/**
 * Get the number of pay intervals until the start date of the next event. Pay intervals are on the first of each month.
 */
export function getIntervalsToEvent(params: { DateBegin: string }) {
  let intervalsToEvent = 0;

  const today = new Date();
  const eventBegin = new Date(params.DateBegin.substring(0, 10) + 'T00:00');

  // Pay intervals are on the first of the month
  let nextInterval = startOfMonth(addMonths(today, 1));

  // Is the next pay interval before the start of the event
  while (
    isBefore(nextInterval, eventBegin) ||
    isSameDay(nextInterval, eventBegin)
  ) {
    // Increase the months until the event by 1
    intervalsToEvent = intervalsToEvent + 1;
    // Set the next interval to be one month after the current interval
    nextInterval = startOfMonth(addMonths(today, intervalsToEvent + 1));
  }

  return intervalsToEvent;
}

/**
 * Get the amount that will be billed monthly until the start date of the next event.
 */
export function getAmountPerInterval(params: {
  Balance: number;
  DateBegin: string;
}) {
  return params.Balance / getIntervalsToEvent({ DateBegin: params.DateBegin });
}
