import { Decimal } from 'decimal.js';

// Adding decimals in JavaScript can do strange things
// Adding these decimal values in JavaScript:
// 0.01 + 0.04 + 0.1 = 0.15000000000000002
// Sum array using JavaScript:
// [0.01, 0.04, 0.1].reduce((accum, value) => accum + value, 0) = 0.15000000000000002
// Using decimal.js:
// sumArray([0.01, 0.04, 0.1]) = 0.15
export function sumArray(data: number[]) {
  return Number(
    data.reduce((accum, value) => accum.plus(value), new Decimal(0))
  );
}
