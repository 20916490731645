import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { InfoWindow, Marker, MarkerProps } from '@react-google-maps/api';
import React from 'react';
import { CRMAgent } from '../../types';
import { toPhoneNumber } from '../../utils/formatter';

const useStyles = makeStyles({
  link: {
    marginTop: 6,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    color: '#4a4a4a',
    textDecorationLine: 'none',
    border: '1px solid #eee',
    borderRadius: 6,
    transition: 'all 0.2s',
    '&:hover': {
      color: '#2a2a2a',
      border: '1px solid #2979ff',
      backgroundColor: '#2979ff11',
    },
  },
});

export function MapMarker(props: {
  contact: CRMAgent;
  clusterer: MarkerProps['clusterer'];
}) {
  // Props
  const { contact } = props;
  // State
  const [open, setOpen] = React.useState(false);
  // Hooks
  const classes = useStyles();

  const cdnBase =
    'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/images/map-markers/';
  const iconColor = cdnBase + 'agent-marker-1.png';

  // Googls Maps URL with address query
  const mapsURL = `https://maps.google.com/?q=${contact.Address1} ${
    contact.Address2 || ''
  }, ${contact.City}, ${contact.State}, ${contact.Zip}`;

  // Phone Number of Agent
  const contactPhone = contact.HomePhone || contact.CellPhone || '';

  if (contact.Latitude && contact.Longitude) {
    return (
      <Marker
        position={{ lat: contact.Latitude, lng: contact.Longitude }}
        onClick={() => setOpen((currentState) => !currentState)}
        icon={iconColor}
        clusterer={props.clusterer}
      >
        {open && (
          <InfoWindow
            position={{ lat: contact.Latitude, lng: contact.Longitude }}
            onCloseClick={() => setOpen(false)}
          >
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 4,
                  marginBottom: 6,
                }}
              >
                <Avatar style={{ backgroundColor: '#212121', color: '#fff' }}>
                  {contact.FirstName
                    ? contact.FirstName.charAt(0).toUpperCase()
                    : ''}
                  {contact.LastName
                    ? contact.LastName.charAt(0).toUpperCase()
                    : ''}
                </Avatar>

                <Typography
                  variant="h6"
                  component="div"
                  style={{ fontWeight: 900, marginLeft: 10 }}
                >
                  {contact.FirstName} {contact.LastName}{' '}
                  {contact.AgentNumber ? ` (${contact.AgentNumber})` : null}
                </Typography>
              </div>
              <Typography variant="subtitle2" style={{ color: grey[600] }}>
                <div>
                  {contact.Address1} {contact.Address2}
                  <br />
                  {contact.City}, {contact.State} {contact.Zip}
                </div>

                <a
                  href={mapsURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <RoomIcon />

                  <strong style={{ marginLeft: 8 }}>Open in Google Maps</strong>
                </a>

                {contactPhone ? (
                  <a href={`tel:${contactPhone}`} className={classes.link}>
                    <PhoneIcon />

                    <strong style={{ marginLeft: 8 }}>
                      Call {toPhoneNumber({ value: contactPhone })}
                    </strong>
                  </a>
                ) : null}
              </Typography>
            </React.Fragment>
          </InfoWindow>
        )}
      </Marker>
    );
  } else {
    return null;
  }
}
