import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { signIn } from '../../utils/authenticate';
import { captureError } from '../../utils/capture-error';
import { navData } from './nav-data';

const useStyles = makeStyles({
  container: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    fontSize: 24,
    marginLeft: 10,
  },
  navButton: {
    flex: 1,
    justifyContent: 'flex-start',
    padding: 10,
  },
  navButtonText: {
    fontSize: 24,
    marginLeft: 10,
  },
});

export function NavDrawer() {
  // Context
  const appContext = React.useContext(AppContext);
  const { state } = React.useContext(AuthContext);
  // Hooks
  const classes = useStyles();
  const { checkPermissions } = usePermissions();

  const queryClient = useQueryClient();
  const handleSignOut = async () => {
    // Remove any cached data
    queryClient.removeQueries();
    // Sign out current user from Cognito
    await Auth.signOut();
    // After being signed out from Cognito
    // Hub.listen > 'auth' > 'signOut' will remove user  data from Context
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  return (
    <Drawer
      open={appContext.state.drawerOpen}
      anchor="left"
      variant="temporary"
      onClose={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
    >
      <div className={classes.container}>
        <div className={classes.section}>
          <IconButton
            onClick={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
            aria-label="close menu"
            size="large"
          >
            <CloseIcon fontSize="large" />
          </IconButton>

          <Typography variant="h6" color="primary" style={{ marginLeft: 10 }}>
            Menu
          </Typography>
        </div>

        <Divider />

        <div style={{ flex: 1, overflowY: 'scroll' }}>
          <List component="nav">
            {navData.map((item) => {
              let canView = true;
              if (item.authenticated) {
                if (item.permissions) {
                  canView = checkPermissions(item.permissions);
                } else {
                  canView = state.user !== undefined;
                }
              }

              if (canView) {
                return (
                  <ListItem key={item.to} button component={Link} to={item.to}>
                    <ListItemText
                      primary={
                        <Typography className={classes.listItemText}>
                          {item.title}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
          </List>
        </div>

        <Divider />

        <div className={classes.section}>
          {state.user !== undefined ? (
            <ButtonBase
              focusRipple
              className={classes.navButton}
              onClick={handleSignOut}
            >
              <ArrowBackIcon fontSize="large" />

              <Typography variant="body1" className={classes.navButtonText}>
                Logout {state.user.getUsername()}
              </Typography>
            </ButtonBase>
          ) : (
            <ButtonBase
              focusRipple
              className={classes.navButton}
              onClick={handleSignIn}
            >
              <ArrowForwardIcon fontSize="large" />

              <Typography variant="body1" className={classes.navButtonText}>
                Sign in
              </Typography>
            </ButtonBase>
          )}
        </div>
      </div>
    </Drawer>
  );
}
