import React from 'react';
import { signIn } from '../utils/authenticate';
import { captureError } from '../utils/capture-error';

export function RedirectSignIn() {
  React.useEffect(() => {
    const handleSignIn = async () => {
      try {
        await signIn({ window });
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    handleSignIn();
  }, []);

  return <div />;
}
