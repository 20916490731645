import orderBy from 'lodash/orderBy';
import { EventData } from '../types';

/**
 * Format a single Event
 */
export function formatEventData(params: { data: EventData }): EventData {
  const { data } = params;

  // Remove the Time Zone from date strings
  // "2021-10-19T14:26:28-04:00" -> "2021-10-19T14:26:28"
  return {
    ...data,
    DateBegin: data.DateBegin ? data.DateBegin.substring(0, 19) : null,
    DateEnd: data.DateEnd ? data.DateEnd.substring(0, 19) : null,
    Agenda: data.Agenda.map((a) => ({
      ...a,
      DateTime: a.DateTime ? a.DateTime.substring(0, 19) : null,
    })),
  };
}

/**
 * Format an array of events
 */
export function formatEventsData(params: { data: EventData[] }): EventData[] {
  const formattedEvents: EventData[] = [];

  // Upcoming events first
  const orderedEvents = orderBy(params.data, 'DateBegin');

  orderedEvents.forEach((i) => {
    const formattedEvent = formatEventData({ data: i });

    formattedEvents.push(formattedEvent);
  });

  return formattedEvents;
}
