import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookLogo } from '../assets/footer-facebook.svg';
import { ReactComponent as InstagramLogo } from '../assets/footer-instagram.svg';
import { ReactComponent as LinkedInLogo } from '../assets/footer-linkedin.svg';
import { ReactComponent as FooterLogo } from '../assets/footer-logo.svg';
import { ReactComponent as TwitterLogo } from '../assets/footer-twitter.svg';
import { ReactComponent as YoutubeLogo } from '../assets/footer-youtube.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 10,
      fontWeight: 'bold',
      fontSize: '1.5em',
    },
    info: {
      padding: '15px 0px ',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-around',
      },
      [theme.breakpoints.only('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down('md')]: {
        borderBottom: '1px solid #999',
        textAlign: 'center',
      },
    },
    wrapper: {
      marginTop: '50px',
      borderTop: '1px solid #999',
      padding: '15px 0px',
    },
    copyright: {
      textAlign: 'center',
      padding: '15px 0px',
      borderTop: '1px solid #999',
    },
    social: {
      display: 'flex',
      justifyContent: 'center',
      padding: 10,
    },
    links: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': { color: '#999' },
    },
    footLogo: {
      fill: grey[900],
      height: 125,
      width: 125,
      '&:hover': { fill: '#999' },
    },
    icons: {
      fill: grey[900],
      height: 50,
      width: 50,
      '&:hover': {
        fill: '#999',
      },
    },
  })
);

export function Footer() {
  const classes = useStyles();

  const links = [
    {
      groupId: 'tours',
      title: 'Quality Leads',
      url: 'https://www.naaleads.com/tour/leads',
    },
    {
      groupId: 'tours',
      title: 'Professional Training',
      url: 'https://www.naaleads.com/tour/training',
    },
    {
      groupId: 'tours',
      title: 'Turnkey System',
      url: 'https://www.naaleads.com/tour/turnkey-system',
    },
    {
      groupId: 'tours',
      title: 'Reliable Products',
      url: 'https://www.naaleads.com/tour/products',
    },
    {
      groupId: 'tours',
      title: 'Compensation',
      url: 'https://www.naaleads.com/tour/compensation',
    },
    {
      groupId: 'about',
      title: 'About The Alliance',
      url: 'https://www.naaleads.com/about',
    },
    {
      groupId: 'about',
      title: 'Our Story',
      url: 'https://www.naaleads.com/about/our-story',
    },
    {
      groupId: 'about',
      title: 'Leadership',
      url: 'https://www.naaleads.com/about/our-leadership',
    },
    {
      groupId: 'about',
      title: 'Impact',
      url: 'https://www.naaleads.com/about/charitable-partners',
    },
    {
      groupId: 'other',
      title: 'Corporate Jobs',
      url: 'https://www.naaleads.com/corporate-jobs',
    },
    {
      groupId: 'other',
      title: 'Contact Us',
      url: 'https://www.naaleads.com/contact',
    },
    {
      groupId: 'other',
      title: 'Sitemap',
      url: 'https://www.naaleads.com/sitemap',
    },
    {
      groupId: 'other',
      title: 'Privacy Policy',
      url: 'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-privacy-notice.pdf',
    },
    {
      groupId: 'other',
      title: 'NAA Terms of Service',
      url: 'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-terms-of-service.pdf',
    },

    {
      groupId: 'other',
      title: 'Do Not Sell My Information',
      url: 'https://submit-irm.trustarc.com/services/validation/d14968e7-edec-4831-b5f2-c494567844f6',
    },
    {
      groupId: 'social',
      icon: <FacebookLogo className={classes.icons} />,
      url: 'https://www.facebook.com/groups/330822977953654',
      title: 'Facebook',
    },
    {
      groupId: 'social',
      icon: <InstagramLogo className={classes.icons} />,
      url: 'https://www.instagram.com/nationalagentsalliance/',
      title: 'Instagram',
    },
    {
      groupId: 'social',
      icon: <TwitterLogo className={classes.icons} />,
      url: 'https://twitter.com/NaaLeadsTheWay',
      title: 'Twitter',
    },
    {
      groupId: 'social',
      icon: <LinkedInLogo className={classes.icons} />,
      url: 'https://www.linkedin.com/company/national-agents-alliance-corporate',
      title: 'LinkedIn',
    },
    {
      groupId: 'social',
      icon: <YoutubeLogo className={classes.icons} />,
      url: 'http://www.youtube.com/user/naatube101',
      title: 'Youtube',
    },
  ];

  return (
    <Grid
      container
      style={{
        padding: '40px',
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          className={classes.wrapper}
          direction="row"
          justifyContent="space-evenly"
        >
          <Grid
            item
            xs={12}
            style={{
              marginTop: '-70px',
              textAlign: 'center',
            }}
          >
            <Link
              to="/"
              aria-label="Link to home page"
              style={{ display: 'inline-block' }}
            >
              <FooterLogo className={classes.footLogo} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.info}>
            <div>
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                Connect
              </Typography>
              <Typography variant="body1">
                The Alliance
                <br /> 1214 Turrentine Street
                <br /> Burlington, NC 27215
                <br /> 336.227.3319
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.info}>
            <div>
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                Tours
              </Typography>
              {links.map((link, key) => {
                return (
                  link.groupId === 'tours' && (
                    <Typography key={key} variant="body1">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.links}
                      >
                        {link.title}
                      </a>
                    </Typography>
                  )
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.info}>
            <div>
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                About
              </Typography>
              {links.map((link, key) => {
                return (
                  link.groupId === 'about' && (
                    <Typography key={key} variant="body1">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.links}
                      >
                        {link.title}
                      </a>
                    </Typography>
                  )
                );
              })}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            className={classes.info}
            style={{ borderBottom: '0px' }}
          >
            <div>
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                Other
              </Typography>
              {links.map((link, key) => {
                return (
                  link.groupId === 'other' && (
                    <Typography key={key} variant="body1">
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.links}
                      >
                        {link.title}
                      </a>
                    </Typography>
                  )
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.copyright}>
          <Grid container className={classes.social}>
            {links.map((link, key) => {
              return (
                link.groupId === 'social' && (
                  <a
                    aria-label={`social media link for ${link.title}`}
                    key={key}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.icon}
                  </a>
                )
              );
            })}
          </Grid>
          <Typography
            variant="body2"
            style={{ color: '#555', marginTop: '15px' }}
          >
            Copyright © 2004 - {new Date().getFullYear()} NAA All Rights
            Reserved
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
