import { CustomerPaymentData } from '../types';

export const cardExpired = (params: {
  expirationDate: CustomerPaymentData['expirationDate'];
}): boolean => {
  if (params.expirationDate) {
    const date = new Date();
    const year = date.getFullYear();
    // getMonth is 0 based
    const month = date.getMonth() + 1;
    let monthFormatted = month.toString();
    if (month < 10) {
      // Add a 0 to the beginning of the value to match Authorize
      // So result is "06" instead of "6"
      monthFormatted = `0${month}`;
    }
    const current = year.toString() + '-' + monthFormatted;

    return Boolean(params.expirationDate < current);
  } else {
    return false;
  }
};
