import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../contexts/app-context';
import { AnimateInProps } from '../animate-in';
import { Footer } from '../footer';
import { RouteError } from './route-error';
import { RouteErrorBoundary } from './route-error-boundary';
import { RouteWrapper } from './route-wrapper';

export function RouteContainer(props: {
  children: React.ReactNode;
  routeTitle: string;
  loading?: boolean;
  headerContent?: React.ReactNode;
  hasError?: boolean;
  paddingBottom?: number;
  hideHeader?: boolean;
  hideFooter?: boolean;
  nofade?: AnimateInProps['nofade'];
  notransform?: AnimateInProps['notransform'];
  noanimation?: boolean;
}) {
  // Context
  const { dispatch } = React.useContext(AppContext);

  React.useEffect(() => {
    // If the drawer is open when this component mounts, close it
    dispatch({ type: 'DRAWER_CLOSE' });
  }, [dispatch]);

  let routeContent = <div />;
  if (props.loading) {
    routeContent = <LinearProgress color="secondary" />;
  } else if (props.hasError) {
    routeContent = <RouteError />;
  } else {
    routeContent = (
      <RouteErrorBoundary>
        <RouteWrapper
          paddingBottom={props.paddingBottom}
          nofade={props.nofade}
          notransform={props.notransform}
          noanimation={props.noanimation}
        >
          {props.children}
          {props.hideFooter ? null : <Footer />}
        </RouteWrapper>
      </RouteErrorBoundary>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Alliance Events | {props.routeTitle}</title>
      </Helmet>

      <div role="main">{routeContent}</div>
    </React.Fragment>
  );
}
