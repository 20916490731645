import { TableCellProps } from '@mui/material/TableCell';

export enum AWS_GROUP {
  Accounting = 'Accounting',
  ARC_Commissions = 'Commissions',
  GlobalAdmin = 'GlobalAdmin',
  ImpersonateAll = 'ImpersonateAll',
  Marketing = 'Marketing',
  AllianceEvents_Admin = 'AllianceEvents_Admin',
  AllianceEvents_CheckIn = 'AllianceEvents_CheckIn',
}

export interface ColumnHeader<T> {
  key: keyof T;
  label?: string;
  align?: TableCellProps['align'];
}

export interface TableState {
  page: number;
  rowsPerPage: number;
  sort: 'asc' | 'desc';
  sortBy: string;
}

export enum PYMNT_DFLT {
  'EVT' = 'EVT',
  'LDS' = 'LDS',
  'PRC' = 'PRC',
}

export interface CustomerPaymentData {
  customerPaymentProfileId: string | null;
  cardNumber: string | null;
  expirationDate: string | null;
  cardType: string | null;
  billToPhone: string | null;
  billToEmail: string | null;
  billToFirstName: string | null;
  billToLastName: string | null;
  billToCompany: string | null;
  billToAddress: string | null;
  billToCity: string | null;
  billToState: string | null;
  billToZip: string | null;
  billToCountry: string | null;
  defaultFor: PYMNT_DFLT[] | null;
}

export interface CustomerData {
  merchantCustomerId: string | null;
  customerProfileId: string | null;
  description: string | null;
  email: string | null;
  username: string | null;
  customerPaymentProfiles: CustomerPaymentData[];
  profileType: string | null;
}

export interface PaymentData {
  customerPaymentProfileId: string;
  payment: {
    creditCard: {
      cardNumber: string;
      expirationDate: string;
      cardType: string;
      issuerNumber: string;
    };
  };
  originalNetworkTransId: string;
  originalAuthAmount: number;
  customerType?: string;
  billTo: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
}

export interface AgentInfoData {
  fname: string | null;
  lname: string | null;
  email: string | null;
  cell_phone: string | null;
  home_phone: string | null;
  hom_city: string | null;
  hom_state: string | null;
}

export interface AgentData {
  status: string | null;
  agentno: string | null;
  fname: string | null;
  mname: string | null;
  lname: string | null;
  agentname: string | null;
  leftcnt: string | null;
  rightcnt: string | null;
  depth: string | null;
  manager: string | null;
  upline: string | null;
  group: string | null;
  email: string | null;
  billinglevel: string | null;
  sub_id: string | null;
  sub_name: string | null;
  is_suspended: string | null;
  busaddr1: string | null;
  busaddr2: string | null;
  busaddr3: string | null;
  buscity: string | null;
  busstate: string | null;
  buszip: string | null;
  hom_addr1: string | null;
  hom_addr2: string | null;
  hom_addr3: string | null;
  hom_city: string | null;
  hom_state: string | null;
  hom_zip: string | null;
  cellphone: string | null;
  homephone: string | null;
  has_pac: string | null;
  cr: string | null;
}

export interface TicketData {
  TicketId: number | null;
  TicketTypeId: number | null;
  EventId: number | null;
  TicketType: string | null;
  TicketStatus: string | null;
  PayStatus: string | null;
  AttendeeTypeId: string | null;
  OwnerTypeId: string | null;
  Price: number | null;
  AmtPaid: number | null;
  Balance: number | null;
  DisplayFirstName: string | null;
  DisplayLastName: string | null;
  DisplayLocation: string | null;
  DisplayOrganization: string | null;
  DisplayEmail: string | null;
  DisplayPhone: string | null;
  AgentBadgeLevel: string | null;
  OwnerUsername: string | null;
  OwnerFirstName: string | null;
  OwnerLastName: string | null;
  OwnerEmail: string | null;
  AssignedToUsername: string | null;
  TransferringToUsername: string | null;
  FirstEvent: 0 | 1 | null;
  CheckedIn: 0 | 1 | null;
  RegistrationDate: string | null;
  Cuid: string | null;
  RegCuid: string | null;
  EventCode: string | null;
  EventTitle: string | null;
  BannerImageName: string | null;
  BannerImageUrl: string | null;
  DateBegin: string | null;
  CreatedDate: string | null;
  GroupName: string | null;
}

export interface CarouselData {
  CarouselId: number;
  CIndex: number;
  Title: string | null;
  Description: string | null;
  ImageName: string | null;
}

export interface ExperienceData {
  ExpId: number;
  EIndex: number;
  Title: string | null;
  Description: string | null;
  ImageName: string | null;
}

export interface EventAccommodationData {
  EventId: EventData['EventId'];
  AccomId: number;
  OrderIndex: number;
  Title: string | null;
  Description: string | null;
  ImageName: string | null;
}

export interface EventSpeakerData {
  EventId: EventData['EventId'];
  SpeakerId: number;
  OrderIndex: number;
  Title: string | null;
  Description: string | null;
  ImageName: string | null;
}

export interface EventSectionData {
  EventId: EventData['EventId'];
  SecId: number;
  OrderIndex: number;
  Title: string | null;
  Description: string | null;
  ImageName: string | null;
}

export interface EventMapData {
  EventId: EventData['EventId'];
  MapId: number;
  OrderIndex: number;
  Title: string | null;
  ImageName: string | null;
}

export interface EventAgendaData {
  EventId: EventData['EventId'];
  AgendaId: number;
  Title: string | null;
  Description: string | null;
  DateTime: string | null;
}

export interface EventTicketData {
  EventId: EventData['EventId'];
  TicketTypeId: number;
  Title: string | null;
  Price: number;
  Description: string | null;
  CreatedDate: string | null;
  MinPayPerTicket: number | null;
  Limit: number | null;
  Purchased: number | null;
  Available: number | null;
  StartDate: string | null;
  EndDate: string | null;
}

export interface EventData {
  EventId: number;
  EventCode: string | null;
  EventStatus: 'Unpublished' | 'Published' | 'Archived' | null;
  EventType: 'Trip' | 'Conference' | null;
  Title: string | null;
  Description: string | null;
  VideoUrl: string | null;
  BannerImage: null;
  BannerImageName: string | null;
  DateBegin: string | null;
  DateEnd: string | null;
  LocationImage: null;
  LocationImageName: string | null;
  LocationTitle: string | null;
  LocationDescription: string | null;
  Attire: string | null;
  InfoDetails: string | null;
  TicketDetails: string | null;
  ArrivalInstructions: string | null;
  TermsAndConditions: string | null;
  TicketLimit: number | null;
  PerUserTicketLimit: number | null;
  TicketsPurchased: number | null;
  TicketsAvailable: number | null;
  CreatedDate: string | null;
  TicketTypes: EventTicketData[];
  Agenda: EventAgendaData[];
  Maps: EventMapData[];
  Accommodations: EventAccommodationData[];
  Speakers: EventSpeakerData[];
  Sections?: EventSectionData[];
}

export interface EventRegistrationData {
  EventId: number;
  TicketLimit: number | null;
  PerUserTicketLimit: number | null;
  TicketsPurchasedByUser: number | null;
  TicketsPurchased: number | null;
  TicketsAvailable: number | null;
  EventCode: string | null;
  EventStatus: string | null;
  EventType: string | null;
  Title: string | null;
  BannerImagePath: string | null;
  DateBegin: string | null;
  DateEnd: string | null;
  TermsAndConditions: string | null;
  CreatedDate: string | null;
  TicketTypes: EventTicketData[];
}

export interface PrinterData {
  PrinterId: number;
  SerialNum: string | null;
  Title: string | null;
  Active: boolean | null;
}

export interface ReportData {
  OwnerUsername: string | null;
  OwnerEmail: string | null;
  OwnerFirstname: string | null;
  OwnerLastname: string | null;
  OwnerPhone: string | null;
  TotalTickets: number | null;
  TotalPrice: number | null;
  TotalAmountPaid: number | null;
  TotalBalance: number | null;
}

export interface PaymentDetailsData {
  TicketPmtId: number | null;
  TicketId?: number | null;
  PayType: string | null;
  PayAmt: number | null;
  ResponseStatus: string | null;
  CreatedDate: string | null;
  TransId: number | null;
  RefId?: number | null;
  TransactionAmt: number | null;
  Description: string | null;
  ResponseCode?: number | null;
  TransResponseStatus?: string | null;
  RefTransID?: number | null;
  AccountNumber: string | null;
  AccountType: string | null;
  Errors: string | null;
  CreatedAt?: string | null;
  ProcessedAt?: string | null;
}

export interface TransactionData {
  refId: number | null;
  username: string | null;
  amount: number | null;
  description: string | null;
  transId: string | null;
  responseStatus: string | null;
  accountNumber: string | null;
  accountType: string | null;
  createdAt: string | null;
  processedAt: string | null;
  ownerPhone: string | null;
  ownerEmail: string | null;
}

export interface CRMAgent {
  AgentNumber: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  AgentStatus: string;
  AgentLevel: string;
  RecAgentNumber: string;
  RecFirstName: string;
  RecLastName: string;
  RecMiddleName: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  Email: string;
  AlternateEmail: string;
  HomePhone: string;
  CellPhone: string;
  BusinessPhone: string;
  Fax: string;
  DateEntered: string;
  RTSDate: string | null;
  YTDAmount: string | null;
  Latitude: number | null;
  Longitude: number | null;
}

export interface HotspotData {
  MeetingId: number;
  HostName: string | null;
  HostImage: string | null;
  Location: string | null;
  Description: string | null;
  DateTime: string | null;
  Latitude: number | null;
  Longitude: number | null;
}
