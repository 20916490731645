import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LogoutIcon from '@mui/icons-material/Logout';
import SecurityIcon from '@mui/icons-material/Security';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Auth } from 'aws-amplify';

export function MFACheck() {
  return (
    <Box
      sx={{
        height: '100dvh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={2} alignItems="center">
        <SecurityIcon sx={{ color: '#b26500', fontSize: 128 }} />

        <Box sx={{ fontWeight: 'bold', fontSize: 32 }}>Set up MFA</Box>

        <Box sx={{ textAlign: 'center', maxWidth: 480, fontSize: 20 }}>
          Please Sign In at{' '}
          <a
            href="https://thealliancecontracting.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://thealliancecontracting.com/
          </a>{' '}
          and select your preferred method of Multi-Factor Authentication.
        </Box>

        <Button
          href="https://thealliancecontracting.com/"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          disableElevation
          sx={{ whiteSpace: 'nowrap' }}
          endIcon={<ArrowForwardIcon />}
        >
          Go to Contracting
        </Button>

        <Button
          variant="outlined"
          sx={{ whiteSpace: 'nowrap' }}
          endIcon={<LogoutIcon />}
          onClick={() => Auth.signOut()}
        >
          Sign out
        </Button>
      </Stack>
    </Box>
  );
}
