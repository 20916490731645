import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Grid from '@mui/material/Grid';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import {
  CustomerData,
  EventRegistrationData,
  EventTicketData,
  TicketData,
} from '../../types';
import { toCurrency } from '../../utils/formatter';

const useStyles = makeStyles((theme) => ({
  ticket: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    borderTop: `8px solid ${red[700]}`,
    borderLeft: 'none',
    borderRadius: '0 0 8px 8px',
    margin: '0 auto',
    marginBottom: 15,
    maxWidth: 800,
    [theme.breakpoints.up('md')]: {
      boxShadow: '2px 0 4px rgba(0, 0, 0, 0.2)',
      borderTop: 'none',
      borderLeft: `8px solid ${red[700]}`,
      borderRadius: '0 8px 8px 0',
    },
  },
  ticketContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  ticketBanner: {
    height: 120,
    width: '100%',
    backgroundColor: '#777',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: 200,
    },
  },
  ticketBannerText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 24,
    textTransform: 'uppercase',
    textShadow: '2px 2px rgba(0,0,0,0.6)',
  },
}));

export function RegisterConfirmation(props: {
  data: EventRegistrationData;
  billing: CustomerData | undefined;
  paymentProfileId: string;
  ticketData: EventTicketData | undefined;
  ticketCount: string;
  ticketsPurchased: TicketData[];
  OwnerEmail?: string;
  payToday?: string;
}) {
  // Props
  const {
    data,
    ticketData,
    billing,
    paymentProfileId,
    ticketCount,
    ticketsPurchased,
    OwnerEmail,
  } = props;
  // Hooks
  const classes = useStyles();

  const purchasedWith = billing?.customerPaymentProfiles.find(
    (i) => i.customerPaymentProfileId === paymentProfileId
  );

  let purchaseTotal =
    ticketData && ticketData.Price
      ? ticketData.Price * Number(ticketCount)
      : null;
  if (props.payToday) {
    purchaseTotal = Number(props.payToday);
  }

  // Show agents a link to their Tickets page
  let linkToTickets: JSX.Element | null = null;
  if (props.payToday) {
    linkToTickets = (
      <p style={{ textAlign: 'center', fontSize: 18 }}>
        Manage your tickets by logging in and visiting your{' '}
        <Link
          to="/account/tickets"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open tickets page in a new tab"
        >
          Tickets
        </Link>{' '}
        page.
      </p>
    );
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div
        style={{
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bold',
          padding: 20,
          color: '#212121',
        }}
      >
        You have registered for {data.Title}!
      </div>

      <div style={{ fontSize: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              padding: 2,
              textAlign: 'right',
              fontWeight: 'bold',
            }}
          >
            Purchased:
          </div>
          <div style={{ flex: 1, padding: 2 }}>
            {ticketCount} x {ticketData?.Title}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              padding: 2,
              textAlign: 'right',
              fontWeight: 'bold',
            }}
          >
            Total:
          </div>
          <div style={{ flex: 1, padding: 2 }}>
            {toCurrency({ value: purchaseTotal || 0 })}
          </div>
        </div>
        {purchasedWith && purchaseTotal !== null && purchaseTotal > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                flex: 1,
                padding: 2,
                textAlign: 'right',
                fontWeight: 'bold',
              }}
            >
              Card:
            </div>
            <div style={{ flex: 1, padding: 2 }}>
              {purchasedWith.cardType}{' '}
              {purchasedWith.cardNumber?.replaceAll('X', '')}
            </div>
          </div>
        ) : null}
      </div>

      <p style={{ textAlign: 'center', fontSize: 18 }}>
        You should receive a confirmation email soon
        {OwnerEmail ? (
          <span>
            {' '}
            at <strong>{OwnerEmail}</strong>
          </span>
        ) : null}
        .
      </p>

      {linkToTickets}

      <div style={{ padding: 10 }}>
        {ticketsPurchased.map((item) => {
          return (
            <div key={item.Cuid} className={classes.ticket}>
              <div className={classes.ticketContent}>
                <div>
                  <div
                    className={classes.ticketBanner}
                    style={{
                      backgroundImage: data.BannerImagePath
                        ? `url(${data.BannerImagePath})`
                        : 'none',
                    }}
                  >
                    {data.DateBegin ? (
                      <div className={classes.ticketBannerText}>
                        {format(new Date(data.DateBegin), 'LLL yyyy')}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  <Grid container spacing={2} style={{ padding: 10 }}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          textAlign: 'center',
                          borderBottom: '1px solid #eaeaea',
                        }}
                      >
                        <Link
                          to={`/ticket/${item.Cuid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Open ticket in a new tab"
                          style={{
                            display: 'inline-block',
                            textDecorationLine: 'none',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingBottom: 5,
                            }}
                          >
                            <div
                              style={{
                                color: '#4a4a4a',
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                                marginRight: 5,
                              }}
                            >
                              {item.Cuid}
                            </div>

                            <OpenInNewIcon fontSize="small" />
                          </div>
                        </Link>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div style={{ textAlign: 'center' }}>
                        <Link
                          to={`/events/${data.EventCode}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="Open event in a new tab"
                          style={{
                            display: 'inline-block',
                            textDecorationLine: 'none',
                          }}
                        >
                          <div
                            style={{
                              color: '#4a4a4a',
                              fontSize: 22,
                              fontFamily: 'Oswald',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: 1.1,
                            }}
                          >
                            {data.Title}
                          </div>
                        </Link>

                        <div
                          style={{
                            display: 'inline-block',
                            marginLeft: 10,
                            color: '#4a4a4a',
                            fontSize: 16,
                            fontWeight: 'bold',
                          }}
                        >
                          {ticketData?.Title}{' '}
                          {toCurrency({ value: item.Price || 0 })}
                        </div>
                      </div>
                    </Grid>

                    {item.DisplayFirstName && item.DisplayLastName ? (
                      <Grid item xs={12} sm={6}>
                        <div style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              color: '#4a4a4a',
                              fontSize: 22,
                              fontFamily: 'Oswald',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: 1.1,
                            }}
                          >
                            {item.DisplayFirstName} {item.DisplayLastName}
                          </div>

                          <div
                            style={{
                              display: 'inline-block',
                              marginLeft: 10,
                              color: '#4a4a4a',
                              fontSize: 16,
                              fontWeight: 'bold',
                            }}
                          >
                            {item.DisplayLocation}
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6}>
                        <div style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              color: '#9b9b9b',
                              fontSize: 22,
                              fontFamily: 'Oswald',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              letterSpacing: 1.1,
                            }}
                          >
                            Unassigned
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
