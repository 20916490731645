import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Carousel from 'react-material-ui-carousel';
import { AnimateIn } from '../../components/animate-in';
import { DisplayMarkup } from '../../components/display-markup';
import { CarouselData } from '../../types';

const useStyles = makeStyles((theme) => ({
  carouselTitle: {
    color: '#fff',
    fontFamily: 'Oswald',
    fontSize: 36,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  carouselContent: {
    maxWidth: 1280,
    margin: '0 auto',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      padding: 50,
    },
  },
  carouselContentWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  carouselImage: {
    marginLeft: 0,
    height: '50vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: '25vw',
      height: '60vh',
    },
  },
  carouselGradient: {
    height: '100%',
    width: '100%',
    background: 'linear-gradient(to top, #121212, #12121200)',
    [theme.breakpoints.up('md')]: {
      background: 'linear-gradient(to right, #121212, #12121200)',
    },
  },
  description: {
    color: '#fff',
    fontSize: 18,
    '& a': {
      color: red[500],
      textDecorationLine: 'none',
    },
  },
}));

export function HomeCarousel(props: { data: CarouselData[] }) {
  // Hooks
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: '#121212' }}>
      <Carousel interval={4000} indicators={false} swipe={false}>
        {props.data.map((item) => {
          return (
            <div
              key={item.CarouselId}
              style={{ backgroundColor: '#121212', height: '60vh' }}
            >
              <div className={classes.carouselContentWrapper}>
                <div className={classes.carouselContent}>
                  <div style={{ maxWidth: 320 }}>
                    <AnimateIn>
                      <div className={classes.carouselTitle}>{item.Title}</div>

                      <DisplayMarkup
                        markup={item.Description}
                        className={classes.description}
                      />
                    </AnimateIn>
                  </div>
                </div>
              </div>

              <div
                className={classes.carouselImage}
                style={{
                  backgroundImage: item.ImageName
                    ? `url(${item.ImageName})`
                    : 'none',
                }}
              >
                <div className={classes.carouselGradient} />
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
