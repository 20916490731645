import isURL from 'validator/lib/isURL';

/**
 * Parse the embed src from the iframe. Both YouTube and Vimeo apply their own formatting to their iframes.
 * Our iframe will scale to fit the page and only needs a url for the video.
 */
export const getIframeSRC = (embed: string) => {
  // '<iframe title="vimeo-player" src="https://player.vimeo.com/video/446474804?h=e865d6f92f" width="640" height="360" frameborder="0" allowfullscreen></iframe>'
  if (embed.includes('src="')) {
    const partsOne = embed.split('src="');
    // partsOne = ["<iframe title=\"vimeo-player\" ", "https://player.vimeo.com/video/446474804?h=e865d6f92f\" width=\"640\" height=\"360\" frameborder=\"0\" allowfullscreen></iframe>"]
    if (partsOne.length) {
      const partsTwo = partsOne[1].split('"');
      // partsTwo = ["https://player.vimeo.com/video/446474804?h=e865d6f92f", " width=", "640", " height=", "360", " frameborder=", "0"," allowfullscreen></iframe>"]
      if (partsTwo.length) {
        const src = partsTwo[0];
        // src = https://player.vimeo.com/video/446474804?h=e865d6f92f
        if (src.startsWith('http')) {
          return src;
        }
      }
    }
  }

  return null;
};

export function VideoFrame(props: { src: string; title?: string }) {
  if (isURL(props.src)) {
    return (
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={props.src}
          title={props.title || 'Alliance Video'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else {
    return null;
  }
}
