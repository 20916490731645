import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const materialTheme = createTheme({
  palette: {
    primary: {
      main: grey[800],
    },
    secondary: {
      main: red[700],
    },
    background: {
      default: '#fafafa',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: 6,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: '1px solid #eaeaea',
          borderRadius: 14,
        },
        sizeMedium: {
          borderRadius: 10,
        },
        sizeSmall: {
          borderRadius: 8,
        },
      },
    },
  },
});
