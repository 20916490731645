import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format';
import React from 'react';
import { Link } from 'react-router-dom';
import { PurchaseTickets } from '../../components/purchase-tickets';
import { RouteContainer } from '../../components/route-container';
import { AppContext } from '../../contexts/app-context';

const useStyles = makeStyles((theme) => ({
  event: {
    margin: 20,
    borderRadius: 10,
    backgroundColor: '#777',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'stretch',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  eventContent: {
    width: '100%',
    flex: 1,
    background: `linear-gradient(to bottom, #121212cc, #12121200)`,
    borderRadius: 10,
    [theme.breakpoints.up('md')]: {
      background: `linear-gradient(to right, #121212cc, #12121200)`,
    },
  },
  eventLink: {
    textDecorationLine: 'none',
    display: 'block',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: 80,
    },
  },
  eventTitle: {
    color: '#fff',
    textShadow: '2px 2px rgba(0,0,0,0.6)',
    fontSize: 48,
    fontWeight: 'bold',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    letterSpacing: 1.1,
    paddingTop: 40,
    paddingBottom: 40,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  eventDate: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 22,
    textShadow: '1px 1px rgba(0,0,0,0.6)',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));

export function Events() {
  // Context
  const { state } = React.useContext(AppContext);
  // Hooks
  const classes = useStyles();

  return (
    <RouteContainer routeTitle="Events" loading={state.eventsLoading}>
      <Container>
        {state.eventsData.map((item) => {
          return (
            <div
              key={item.EventId}
              className={classes.event}
              style={{
                backgroundImage: item.BannerImageName
                  ? `url(${item.BannerImageName})`
                  : 'none',
              }}
            >
              <div className={classes.eventContent}>
                <Link
                  to={`/events/${item.EventCode}`}
                  className={classes.eventLink}
                >
                  <div className={classes.eventTitle}>{item.Title}</div>

                  {item.DateBegin && item.DateEnd ? (
                    <div className={classes.eventDate}>
                      {format(new Date(item.DateBegin), 'MMM d')} -{' '}
                      {format(new Date(item.DateEnd), 'PP')}
                    </div>
                  ) : null}
                </Link>
              </div>

              <div style={{ padding: 60 }}>
                <PurchaseTickets data={item} size="large" />
              </div>
            </div>
          );
        })}
      </Container>
    </RouteContainer>
  );
}
