import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { orderBy } from 'lodash';
import React from 'react';
import { EventMapData } from '../../types';

export function Maps(props: { data: EventMapData[] }) {
  const items = orderBy(props.data, 'OrderIndex');

  if (props.data.length) {
    return (
      <React.Fragment>
        <Typography
          variant="h4"
          color="primary"
          gutterBottom
          style={{ fontFamily: 'Oswald' }}
        >
          Maps
        </Typography>

        <Divider />
        <Grid
          container
          spacing={3}
          justifyContent="space-evenly"
          style={{ padding: 10 }}
        >
          {items.map((item) => {
            return (
              <Grid
                key={item.MapId}
                item
                xs={12}
                sm={6}
                style={{ padding: 20 }}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ fontWeight: 'bold', fontFamily: 'Oswald' }}
                    >
                      {item.Title}
                    </Typography>
                  </div>
                  <div style={{ padding: 10 }}>
                    <img
                      alt="map"
                      width="100%"
                      src={item.ImageName ? item.ImageName : 'none'}
                    />
                  </div>
                  <Button
                    size="large"
                    color="secondary"
                    style={{
                      textDecorationLine: 'underline',
                    }}
                    onClick={() => {
                      window.open(item.ImageName ? item.ImageName : 'none');
                    }}
                  >
                    View Map
                  </Button>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
