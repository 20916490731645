import ErrorIcon from '@mui/icons-material/Error';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useWindowWidth } from '@react-hook/window-size';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnimateIn } from '../../components/animate-in';
import { CardIcon } from '../../components/card-icon';
import {
  CustomerData,
  EventRegistrationData,
  EventTicketData,
  PYMNT_DFLT,
  TicketData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { cardExpired } from '../../utils/card-expired';
import { toCurrency } from '../../utils/formatter';
import {
  getAmountPerInterval,
  getIntervalsToEvent,
} from '../../utils/payments';
import { RegisterConfirmation } from './register-confirmation';
import { RegisterPaymentAdd } from './register-payment-add';
import { RegisterTermsAccept } from './register-terms-accept';
import { RegisterTicketSelect } from './register-ticket-select';
import { SummaryPaymentSchedule } from './summary-payment-schedule';

export function RegisterAgent(props: {
  data: EventRegistrationData;
  user: CognitoUser;
}) {
  // State
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [register, setRegister] = React.useState({
    customerPaymentProfileId: '',
    EventId: 0,
    TicketTypeId: 0,
    ticketCount: '1',
    totalPay: '0',
  });
  const [ticket, setTicket] = React.useState<EventTicketData>();
  const [hasAgreed, setHasAgreed] = React.useState(false);
  const [purchaseComplete, setPurchaseComplete] = React.useState(false);
  const [purchaseError, setPurchaseError] = React.useState(false);
  const [purchaseErrorMsg, setPurchaseErrorMsg] = React.useState('');
  const [ticketsPurchased, setTicketsPurchased] = React.useState<TicketData[]>(
    [],
  );
  const [billing, setBilling] = React.useState<CustomerData>();
  // Hooks
  const width = useWindowWidth();

  React.useEffect(() => {
    const ticketTypes = orderBy(props.data.TicketTypes, 'Price');
    if (ticketTypes.length) {
      // Default to paying full price for the cheapest ticket
      const ticketType = ticketTypes[0];
      setRegister((currentState) => ({
        ...currentState,
        EventId: props.data.EventId,
        TicketTypeId: ticketType.TicketTypeId,
        totalPay: ticketType.Price.toString(),
      }));
      setTicket(ticketType);
    }
  }, [props.data]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Get Agent Payment Info
        const response: {
          data: CustomerData;
        } = await API.post('EventsAPI', '/events/payments/customer-profile', {
          body: {},
        });
        setBilling(response.data);
        // If the Agent has payment profiles
        if (response.data.customerPaymentProfiles.length) {
          const { customerPaymentProfiles } = response.data;
          // Check to to see if the user has set a default payment profile
          const defaultProfile = customerPaymentProfiles.find(
            (i) => i.defaultFor?.includes(PYMNT_DFLT['EVT']),
          );
          if (defaultProfile !== undefined) {
            setRegister((currentState) => ({
              ...currentState,
              customerPaymentProfileId:
                defaultProfile.customerPaymentProfileId || '',
            }));
          } else {
            // The user has not set a default profile so just use their first payment profile
            const { customerPaymentProfileId } = customerPaymentProfiles[0];
            setRegister((currentState) => ({
              ...currentState,
              customerPaymentProfileId: customerPaymentProfileId || '',
            }));
          }
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.user]);

  const handlePurchase = async () => {
    try {
      setSubmitting(true);
      setPurchaseError(false);
      setPurchaseErrorMsg('');

      const response: {
        data: TicketData[];
      } = await API.post('EventsAPI', '/events/tickets/purchase', {
        body: register,
      });
      if (response.data.length) {
        setTicketsPurchased(response.data);
      }
      setPurchaseComplete(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error?.response?.data) {
        const errData = error.response.data;
        if (errData?.status === 'invalid') {
          if (errData?.errors?.declined) {
            setPurchaseErrorMsg(errData.errors.declined);
          } else if (errData?.errors?.ticketCount) {
            setPurchaseErrorMsg(errData?.errors?.ticketCount[0]);
          } else if (errData?.error?.includes('400')) {
            setPurchaseErrorMsg('The transaction was unsuccessful.');
          }
        }
      }
      captureError({ data: { error, ticket } });
      setPurchaseError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const ticketIsFree: boolean = Boolean(
    ticket &&
      ticket.MinPayPerTicket !== null &&
      ticket.MinPayPerTicket === 0 &&
      ticket.Price !== null &&
      ticket.Price === 0,
  );

  // The amount of tickets the user can purchase for this event
  const startLimit = 20;
  let ticketSelectLimits = [startLimit];

  // There are still tickets available for this event
  let availableTicketsEvent: boolean = false;
  if (props.data.TicketLimit === null) {
    // There is no ticket limit for this event
    availableTicketsEvent = true;
  } else if (
    props.data.TicketsAvailable !== null &&
    props.data.TicketsAvailable > 0
  ) {
    // There are still tickets available for this event
    availableTicketsEvent = true;

    // Add this value as a select option
    ticketSelectLimits.push(props.data.TicketsAvailable);
  }

  // There are still tickets available for the selected ticket type
  let availableTicketsType: boolean = false;
  if (ticket !== undefined) {
    if (ticket.Limit === null) {
      // There is no limit on this ticket type
      availableTicketsType = true;
    } else if (ticket.Available !== null && ticket.Available > 0) {
      // There are still tickets of this type available for purchase
      availableTicketsType = true;

      // Add this value as a select option
      ticketSelectLimits.push(ticket.Available);
    }
  }

  // The user has not exceeded their purchase limit for the event and can purchase more tickets
  let availableTicketsUser: boolean = false;
  if (props.data.PerUserTicketLimit === null) {
    // There is no per user ticket limit for event
    availableTicketsUser = true;
  } else if (
    props.data.TicketsPurchasedByUser !== null &&
    props.data.PerUserTicketLimit !== null &&
    props.data.TicketsPurchasedByUser < props.data.PerUserTicketLimit
  ) {
    // The user has not exceeded their limit
    availableTicketsUser = true;

    // Add this value as a select option
    ticketSelectLimits.push(
      props.data.PerUserTicketLimit - props.data.TicketsPurchasedByUser,
    );
  }

  // The user can proceed with purchasing tickets for this event
  const canPurchase =
    availableTicketsEvent && availableTicketsType && availableTicketsUser;

  // Find the lowest of the ticket select limit
  const defaultLimit = Math.min(...ticketSelectLimits);

  // The amount they are paying today as a number instead of a string
  const payTodayAmount = Number(register.totalPay);
  // The highest amount they could possibly, full price for all tickets
  let payTodayMax = 0;
  // The lowest amount they could possibly, min price for all tickets
  let payTodayMin = 0;
  if (ticket && register.ticketCount) {
    payTodayMax = ticket.Price
      ? ticket.Price * Number(register.ticketCount)
      : 0;
    payTodayMin = ticket.MinPayPerTicket
      ? ticket.MinPayPerTicket * Number(register.ticketCount)
      : 0;
  }
  // The difference between the highest amount they could pay and what they are paying
  const payTodayBalance: number = payTodayMax - payTodayAmount;

  let intervalsToEvent = 0;
  let amountPerInterval = 0;
  if (props.data.DateBegin) {
    intervalsToEvent = getIntervalsToEvent({
      DateBegin: props.data.DateBegin,
    });
    amountPerInterval = getAmountPerInterval({
      Balance: payTodayBalance,
      DateBegin: props.data.DateBegin,
    });
  }
  // This ticket type has to be paid in full
  let fullPriceTicket: boolean = false;
  if (ticket && ticket.Price && ticket.MinPayPerTicket) {
    fullPriceTicket = ticket.Price === ticket.MinPayPerTicket;
  }
  // No intervales left or the ticket has to be paid in full
  const hasToPayInFull: boolean = intervalsToEvent === 0 || fullPriceTicket;
  const isPayingInFull: boolean = payTodayMax === payTodayAmount;
  const isPayingAbove: boolean = payTodayAmount > payTodayMax;
  const isPayingBelow: boolean = payTodayAmount < payTodayMin;
  const displayIntervals: boolean =
    !hasToPayInFull && !isPayingInFull && !isPayingAbove && !isPayingBelow;

  const isDesktop = width > 1024;

  const steps: { label: string; content: (index: number) => JSX.Element }[] = [
    {
      label: 'Select Ticket',
      content: (index) => {
        const nextDisabled: boolean =
          !register.TicketTypeId || register.ticketCount === '0';

        return (
          <div>
            <RegisterTicketSelect
              data={props.data}
              TicketTypeId={register.TicketTypeId}
              onSelect={(ticket) => {
                const { Price, TicketTypeId } = ticket;
                // Update selected ticket and amount to pay
                setRegister((currentState) => {
                  const totalPay = Price * Number(currentState.ticketCount);
                  return {
                    ...currentState,
                    TicketTypeId,
                    totalPay: totalPay.toString(),
                  };
                });
                setTicket(ticket);
              }}
            />

            <Typography variant="h6" component="div" gutterBottom>
              Number of Tickets
            </Typography>

            <div style={{ width: '100%', maxWidth: 480 }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="ticket-count-label">Tickets</InputLabel>
                <Select
                  labelId="ticket-count-label"
                  id="ticket-count"
                  label="Tickets"
                  value={register.ticketCount}
                  onChange={(event) => {
                    const ticketCount = event.target.value as string;
                    const totalPay = ticket
                      ? ticket.Price * Number(ticketCount)
                      : 0;
                    setRegister((currentState) => ({
                      ...currentState,
                      ticketCount,
                      totalPay: totalPay.toString(),
                    }));
                  }}
                >
                  {Array.from({ length: defaultLimit }).map((_, index) => {
                    return (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {index + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div style={{ textAlign: 'center', margin: 2 }}>
                <small>
                  You can assign your tickets to attendees after you've
                  purchased the tickets.
                </small>
              </div>

              {ticket && ticket.Price > 0 && hasToPayInFull ? (
                <div style={{ textAlign: 'center', margin: 2 }}>
                  <small>
                    {ticket.Title} tickets must be paid in full at time of
                    purchase.
                  </small>
                </div>
              ) : ticket && intervalsToEvent > 0 ? (
                <div style={{ textAlign: 'center', margin: 2 }}>
                  <small>
                    Minimum of {toCurrency({ value: ticket.MinPayPerTicket })}{' '}
                    per ticket due today.
                  </small>
                </div>
              ) : null}

              {props.data.PerUserTicketLimit !== null &&
              props.data.PerUserTicketLimit < startLimit ? (
                <div style={{ textAlign: 'center', margin: 2 }}>
                  <small>
                    Users are limited to purchasing{' '}
                    {props.data.PerUserTicketLimit} ticket(s) for this event.
                  </small>
                </div>
              ) : null}

              {props.data.PerUserTicketLimit !== null &&
              props.data.TicketsPurchasedByUser !== null &&
              props.data.PerUserTicketLimit -
                props.data.TicketsPurchasedByUser <
                startLimit ? (
                <div style={{ textAlign: 'center', margin: 2 }}>
                  <small>
                    You can purchase up to{' '}
                    {props.data.PerUserTicketLimit -
                      props.data.TicketsPurchasedByUser}{' '}
                    more ticket(s) for this event.
                  </small>
                </div>
              ) : null}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={nextDisabled}
                onClick={() => {
                  setActiveStep(index + 1);
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Review Terms',
      content: (index) => {
        const nextDisabled: boolean = !hasAgreed;

        return (
          <div>
            <RegisterTermsAccept
              data={props.data}
              accepted={hasAgreed}
              onChange={(checked) => setHasAgreed(checked)}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveStep(index - 1);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={nextDisabled}
                onClick={() => {
                  setActiveStep(index + 1);
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      label: 'Complete Purchase',
      content: (index) => {
        return (
          <div>
            <div style={{ padding: 10 }}>
              <Typography variant="h6" component="div" gutterBottom>
                Total
              </Typography>

              <AnimateIn to="bottom">
                <div style={{ padding: 10 }}>
                  {ticket ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ fontSize: 18 }}>
                        {register.ticketCount} x {ticket.Title} at{' '}
                        {toCurrency({ value: ticket.Price })}
                      </div>

                      <div
                        style={{
                          flex: 1,
                          height: 1,
                          backgroundColor: '#00000033',
                          marginLeft: 20,
                          marginRight: 20,
                        }}
                      />

                      <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                        {toCurrency({ value: payTodayMax })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </AnimateIn>

              {ticketIsFree ? null : (
                <React.Fragment>
                  <Typography variant="h6" component="div" gutterBottom>
                    Payment
                  </Typography>

                  <div style={{ padding: 10 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          disabled={hasToPayInFull}
                        >
                          <InputLabel htmlFor="purchase-pay-today">
                            Pay Today
                          </InputLabel>
                          <OutlinedInput
                            id="purchase-pay-today"
                            label="Pay Today"
                            type="number"
                            value={register.totalPay}
                            onChange={(event) =>
                              setRegister((currentState) => ({
                                ...currentState,
                                totalPay: event.target.value as string,
                              }))
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                          />
                        </FormControl>

                        {!hasToPayInFull ? (
                          <React.Fragment>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Button
                                fullWidth
                                disableElevation
                                size="small"
                                variant="contained"
                                style={{ margin: 2 }}
                                onClick={() =>
                                  setRegister((currentState) => ({
                                    ...currentState,
                                    totalPay: payTodayMin.toString(),
                                  }))
                                }
                              >
                                Min
                              </Button>

                              <Button
                                fullWidth
                                disableElevation
                                size="small"
                                variant="contained"
                                style={{ margin: 2 }}
                                onClick={() =>
                                  setRegister((currentState) => ({
                                    ...currentState,
                                    totalPay: (payTodayMax * 0.5).toString(),
                                  }))
                                }
                              >
                                Half
                              </Button>

                              <Button
                                fullWidth
                                disableElevation
                                size="small"
                                variant="contained"
                                style={{ margin: 2 }}
                                onClick={() =>
                                  setRegister((currentState) => ({
                                    ...currentState,
                                    totalPay: payTodayMax.toString(),
                                  }))
                                }
                              >
                                Full
                              </Button>
                            </div>

                            {ticket ? (
                              <div style={{ textAlign: 'center' }}>
                                <small>
                                  Minimum of{' '}
                                  {toCurrency({
                                    value: ticket.MinPayPerTicket,
                                  })}{' '}
                                  per ticket due today.
                                </small>
                              </div>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                      </Grid>

                      {billing?.customerPaymentProfiles.length ? (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="register-agent-card-label">
                              Card
                            </InputLabel>
                            <Select
                              labelId="register-agent-card-label"
                              id="register-agent-card"
                              label="Card"
                              value={register.customerPaymentProfileId}
                              onChange={(event) => {
                                const profileId = event.target.value as string;
                                setRegister((currentState) => ({
                                  ...currentState,
                                  customerPaymentProfileId: profileId,
                                }));
                              }}
                            >
                              {billing?.customerPaymentProfiles.map(
                                (profile) => {
                                  if (profile.customerPaymentProfileId) {
                                    const { expirationDate } = profile;
                                    return (
                                      <MenuItem
                                        key={profile.customerPaymentProfileId}
                                        value={profile.customerPaymentProfileId}
                                      >
                                        <span style={{ marginRight: 5 }}>
                                          <CardIcon
                                            cardType={profile.cardType || ''}
                                          />
                                        </span>
                                        {profile.cardType} -{' '}
                                        {profile.cardNumber} - {expirationDate}
                                        {cardExpired({ expirationDate }) ? (
                                          <Chip
                                            label="Expired"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            icon={<ErrorIcon />}
                                            style={{
                                              fontWeight: 'bold',
                                              marginLeft: 4,
                                            }}
                                          />
                                        ) : null}
                                      </MenuItem>
                                    );
                                  } else {
                                    return null;
                                  }
                                },
                              )}
                            </Select>
                          </FormControl>

                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              padding: 2,
                            }}
                          >
                            <RegisterPaymentAdd
                              isAgent
                              onUpdateBilling={(newBilling) => {
                                setBilling(newBilling);
                              }}
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6}>
                          <RegisterPaymentAdd
                            isAgent
                            buttonLarge
                            buttonText="Add Payment Information"
                            onUpdateBilling={(newBilling) => {
                              setBilling(newBilling);
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        {displayIntervals ? (
                          <p style={{ textAlign: 'right' }}>
                            On the <strong>1st</strong> of each month you will
                            be charged{' '}
                            <strong>
                              {toCurrency({ value: amountPerInterval })}
                            </strong>{' '}
                            for the remaining{' '}
                            <strong>
                              {toCurrency({ value: payTodayBalance })}
                            </strong>{' '}
                            ticket balance.
                          </p>
                        ) : null}

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <SummaryPaymentSchedule
                            data={props.data}
                            payToday={Number(register.totalPay)}
                            displayIntervals={displayIntervals}
                            intervalsToEvent={intervalsToEvent}
                            amountPerInterval={amountPerInterval}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              )}
            </div>

            {purchaseError ? (
              <div style={{ padding: 10 }}>
                <Alert severity="error" onClose={() => setPurchaseError(false)}>
                  <strong style={{ marginRight: 10 }}>
                    An error has occurred!
                  </strong>
                  {purchaseErrorMsg ? (
                    <small>{purchaseErrorMsg}</small>
                  ) : (
                    <small>Please refresh the page and try again.</small>
                  )}
                </Alert>
              </div>
            ) : null}

            <div
              style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                disabled={submitting}
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={() => {
                  setActiveStep(index - 1);
                  window.scrollTo(0, 0);
                }}
              >
                Back
              </Button>

              {ticketIsFree ? (
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={submitting || purchaseError}
                  onClick={handlePurchase}
                >
                  {submitting
                    ? 'Registering...'
                    : `Register ${register.ticketCount} ticket(s)`}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  disabled={
                    submitting ||
                    purchaseError ||
                    !register.customerPaymentProfileId ||
                    Number(register.totalPay) < payTodayMin ||
                    Number(register.totalPay) > payTodayMax
                  }
                  onClick={handlePurchase}
                >
                  {submitting
                    ? 'Purchasing...'
                    : `Purchase and Pay ${toCurrency({
                        value: Number(register.totalPay),
                      })} today`}
                </Button>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  if (loading) {
    return <LinearProgress />;
  } else if (purchaseComplete) {
    return (
      <RegisterConfirmation
        data={props.data}
        billing={billing}
        paymentProfileId={register.customerPaymentProfileId}
        payToday={register.totalPay}
        ticketData={ticket}
        ticketCount={register.ticketCount}
        ticketsPurchased={ticketsPurchased}
      />
    );
  } else if (canPurchase) {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <Stepper
          activeStep={activeStep}
          orientation={isDesktop ? 'horizontal' : 'vertical'}
          sx={{ p: 2 }}
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              {isDesktop ? null : (
                <StepContent>{step.content(index)}</StepContent>
              )}
            </Step>
          ))}
        </Stepper>

        {isDesktop ? (
          <div style={{ backgroundColor: '#fff', padding: 20 }}>
            {steps.map((step, index) => {
              if (activeStep === index) {
                return <div key={step.label}>{step.content(index)}</div>;
              } else {
                return null;
              }
            })}
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: '#fff', padding: 40 }}>
        <Typography
          variant="h6"
          style={{
            margin: '0 auto',
            width: '100%',
            maxWidth: 600,
            textAlign: 'center',
          }}
        >
          This event has sold all available tickets or you have reached the
          limit on number of tickets allowed for this event.
        </Typography>

        <Typography align="center" style={{ marginTop: 40 }}>
          <Link to="/account/tickets">View your tickets</Link>
        </Typography>
      </div>
    );
  }
}
