import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format, formatDistanceToNow } from 'date-fns';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DisplayMarkup } from '../../components/display-markup';
import { EventAgenda } from '../../components/event-agenda';
import { PurchaseTickets } from '../../components/purchase-tickets';
import { RouteContainer } from '../../components/route-container';
import { VideoFrame } from '../../components/video-frame';
import { EventData, EventSectionData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formatEventData } from '../../utils/format-events';
import { toCurrency } from '../../utils/formatter';
import { EventNotFound } from './event-not-found';
import { Maps } from './maps';

const sectionKeys = {
  location: 'event-section-location',
  agenda: 'event-section-agenda',
  accomodations: 'event-section-accomodations',
  speakers: 'event-section-speakers',
  getSectionKey: (section: EventSectionData) => {
    return `event-section-${section.SecId}`;
  },
};

const useStyles = makeStyles((theme) => ({
  scrollButtons: {
    color: '#fff',
  },
  eventBanner: {
    width: '100%',
    height: 400,
    backgroundColor: '#777',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  eventBackground: {
    background: `linear-gradient(to right, #12121200, #12121299, #12121200)`,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  eventTitle: {
    color: '#fff',
    textShadow: '2px 2px rgba(0,0,0,0.6)',
    fontWeight: 'bold',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    letterSpacing: 1.1,
    textAlign: 'center',
    fontSize: 48,
    [theme.breakpoints.up('md')]: {
      fontSize: 64,
    },
  },
  eventPurchase: {
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 60,
    },
  },
  tabItem: {
    fontSize: 16,
    fontFamily: 'Oswald',
  },
  ticket: {
    display: 'flex',
    padding: 10,
    marginTop: 10,
    border: '1px solid #eaeaea',
    borderRadius: 8,
  },
  ticketTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    paddingLeft: 5,
    borderLeft: `4px solid ${red[700]}`,
  },
}));

type RouteParams = { eventId?: string };
export function Event() {
  // State
  const [position, setPosition] =
    React.useState<AppBarProps['position']>('static');
  // Hooks
  const classes = useStyles();
  const width = useWindowWidth();
  const params: RouteParams = useParams();

  // Query - Event
  const path = '/events';
  const query = useQuery({
    queryKey: [
      path,
      {
        EventCode: params.eventId,
        EventStatus: ['Published'],
        EventType: ['Conference'],
        EventDetails: [{ activeTicketTypeOnly: 1 }],
      },
    ],
    queryFn: async () => {
      const response: {
        data: EventData[];
      } = await API.post('EventsAPI', path, {
        body: {
          EventCode: params.eventId,
          EventStatus: ['Published'],
          EventType: ['Conference'],
          EventDetails: [{ activeTicketTypeOnly: 1 }],
        },
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Event data
  let data: EventData | undefined;
  if (query.data && query.data.length) {
    data = formatEventData({ data: query.data[0] });
  }

  // For the page sections navigation bar change its position based on scroll
  React.useEffect(() => {
    window?.addEventListener('scroll', () => {
      const heightOfImage = 400;

      if (window && window.scrollY > heightOfImage) {
        setPosition('fixed');
      } else {
        setPosition('static');
      }
    });

    return () => window?.removeEventListener('scroll', () => {});
  }, []);

  const handleScroll = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 140;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
  };

  let content = <div />;
  if (data) {
    // If all content in the current RTE Editor is removed this is what will remain
    const editorEmpty = '\u003Cp\u003E\u003Cbr\u003E\u003C/p\u003E';
    // Check for What You Get Info
    const hasInfoDetails =
      data.InfoDetails !== null && data.InfoDetails !== editorEmpty;
    // Check for General Ticket Info
    const hasTicketDetails =
      data.TicketDetails !== null && data.TicketDetails !== editorEmpty;
    // Check for Ticket Types
    const hasTicketTypes = Boolean(data.TicketTypes.length);

    let promoVideo: JSX.Element | null = null;
    if (data.VideoUrl) {
      promoVideo = (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: '100%', maxWidth: 960 }}>
            <VideoFrame src={data.VideoUrl} />
          </div>
        </Grid>
      );
    }

    const buyTicketsBanner = data.TicketTypes.length ? (
      <Box
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          backgroundColor: '#777',
          backgroundImage: data.BannerImageName
            ? `url(${data.BannerImageName})`
            : 'none',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'stretch',
        }}
      >
        <Box
          component="div"
          sx={{
            p: 2,
            width: '100%',
            flex: 1,
            background: {
              xs: `linear-gradient(to bottom, #121212cc, #12121200)`,
              md: `linear-gradient(to right, #121212cc, #12121200)`,
            },
          }}
        >
          <Box
            component={Link}
            to={`/events/${data.EventCode}/register`}
            sx={{
              textDecorationLine: 'none',
              display: 'block',
              height: '100%',
              width: '100%',
            }}
          >
            <Box
              sx={{
                color: '#fff',
                textShadow: '2px 2px rgba(0,0,0,0.6)',
                fontSize: 32,
                fontWeight: 'bold',
                fontFamily: 'Oswald',
                textTransform: 'uppercase',
                letterSpacing: 1.1,
                paddingTop: { xs: 4, md: 0 },
                paddingBottom: { xs: 4, md: 0 },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              {data.Title}
            </Box>

            {data.DateBegin && data.DateEnd ? (
              <Box
                sx={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: 18,
                  textShadow: '1px 1px rgba(0,0,0,0.6)',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                {format(new Date(data.DateBegin), 'MMM d')} -{' '}
                {format(new Date(data.DateEnd), 'PP')}
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <PurchaseTickets data={data} size="large" />
        </Box>
      </Box>
    ) : null;

    content = (
      <React.Fragment>
        <div
          className={classes.eventBanner}
          style={{
            backgroundImage: data.BannerImageName
              ? `url(${data.BannerImageName})`
              : 'none',
          }}
        >
          <div className={classes.eventBackground}>
            <div>
              <div className={classes.eventTitleWrapper}>
                <div className={classes.eventTitle}>{data.Title}</div>

                {data.TicketTypes.length ? (
                  <div className={classes.eventPurchase}>
                    <PurchaseTickets data={data} size="large" />
                  </div>
                ) : null}
              </div>

              {data.DateBegin && data.DateEnd ? (
                <div style={{ marginTop: 5, textAlign: 'center' }}>
                  <div
                    style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}
                  >
                    {format(new Date(data.DateBegin), 'MMM d')} -{' '}
                    {format(new Date(data.DateEnd), 'PP')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {position === 'fixed' ? <Box sx={{ height: '48px' }} /> : null}

        <AppBar
          elevation={0}
          position={position}
          sx={{ backgroundColor: 'rgba(0,0,0,0.85)', top: { xs: 56, md: 64 } }}
        >
          <Container disableGutters>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab
                label="Location"
                sx={{ color: '#fff' }}
                onClick={() => handleScroll(sectionKeys.location)}
              />

              {data.Agenda.length ? (
                <Tab
                  label="Agenda"
                  sx={{ color: '#fff' }}
                  onClick={() => handleScroll(sectionKeys.agenda)}
                />
              ) : null}

              {orderBy(data.Sections, 'OrderIndex').map((section) => {
                return (
                  <Tab
                    key={section.SecId}
                    label={section.Title}
                    sx={{ color: '#fff' }}
                    onClick={() =>
                      handleScroll(sectionKeys.getSectionKey(section))
                    }
                  />
                );
              })}

              {data.Speakers.length ? (
                <Tab
                  label="Speakers"
                  sx={{ color: '#fff' }}
                  onClick={() => handleScroll(sectionKeys.speakers)}
                />
              ) : null}

              {data.Accommodations.length ? (
                <Tab
                  label="Hotel Information"
                  sx={{ color: '#fff' }}
                  onClick={() => handleScroll(sectionKeys.accomodations)}
                />
              ) : null}
            </Tabs>
          </Container>
        </AppBar>

        <Container style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            {promoVideo}

            <Grid item xs={12}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ fontFamily: 'Oswald' }}
                  >
                    {data.Title}
                  </Typography>
                </div>

                <Typography variant="body1">
                  in{' '}
                  {data.DateBegin
                    ? formatDistanceToNow(new Date(data.DateBegin))
                    : null}{' '}
                  from{' '}
                  {data.DateBegin
                    ? format(new Date(data.DateBegin), 'eee MMM do')
                    : null}{' '}
                  to{' '}
                  {data.DateEnd
                    ? format(new Date(data.DateEnd), 'eee MMM do yyyy')
                    : null}
                </Typography>

                <DisplayMarkup markup={data.Description} />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div>
                <div id={sectionKeys.location} />
                <Typography
                  variant="h4"
                  component="div"
                  color="primary"
                  gutterBottom
                  style={{ fontFamily: 'Oswald' }}
                >
                  Location
                </Typography>

                <Divider />

                <div
                  style={{
                    padding: 20,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      height: 200,
                      borderRadius: 10,
                      backgroundColor: '#777',
                      backgroundImage: data.LocationImageName
                        ? `url(${data.LocationImageName})`
                        : 'none',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  />

                  <div style={{ marginTop: 15 }}>
                    <strong style={{ fontSize: 16 }}>
                      {data.LocationTitle}
                    </strong>
                    <DisplayMarkup markup={data.LocationDescription} />
                  </div>
                </div>
              </div>
            </Grid>

            {data.Agenda.length ? (
              <Grid item xs={12} sm={6}>
                <div id={sectionKeys.agenda} />
                <Typography
                  variant="h4"
                  component="div"
                  color="primary"
                  gutterBottom
                  style={{ fontFamily: 'Oswald' }}
                >
                  Agenda
                </Typography>

                <Divider style={{ marginBottom: 10 }} />

                <EventAgenda data={data.Agenda} />
              </Grid>
            ) : null}

            {data.Sections?.length ? (
              <Grid item xs={12}>
                {buyTicketsBanner}
              </Grid>
            ) : null}

            {orderBy(data.Sections, 'OrderIndex').map((section) => {
              return (
                <Grid key={section.SecId} item xs={12} sm={6}>
                  <div id={sectionKeys.getSectionKey(section)} />
                  <Typography
                    variant="h4"
                    component="div"
                    color="primary"
                    gutterBottom
                    style={{ fontFamily: 'Oswald' }}
                  >
                    {section.Title}
                  </Typography>

                  <Divider />

                  {section.ImageName ? (
                    <div
                      style={{
                        margin: 20,
                        height: 260,
                        borderRadius: 10,
                        backgroundColor: '#777',
                        backgroundImage: section.ImageName
                          ? `url(${section.ImageName})`
                          : 'none',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}
                    />
                  ) : null}

                  <DisplayMarkup markup={section.Description} />
                </Grid>
              );
            })}

            {hasInfoDetails ? (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h4"
                  component="div"
                  color="primary"
                  gutterBottom
                  style={{ fontFamily: 'Oswald' }}
                >
                  What You Get
                </Typography>

                <Divider />

                <DisplayMarkup markup={data.InfoDetails} />
              </Grid>
            ) : null}

            {hasTicketDetails || hasTicketTypes ? (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h4"
                  component="div"
                  color="primary"
                  gutterBottom
                  style={{ fontFamily: 'Oswald' }}
                >
                  Ticket Details
                </Typography>

                <Divider />

                {hasTicketDetails ? (
                  <DisplayMarkup markup={data.TicketDetails} />
                ) : null}

                {hasTicketTypes ? (
                  <div>
                    {orderBy(data.TicketTypes, 'Price').map((ticket) => {
                      return (
                        <div
                          key={ticket.TicketTypeId}
                          className={classes.ticket}
                        >
                          <div style={{ flex: 1 }}>
                            <div className={classes.ticketTitle}>
                              {ticket.Title} -{' '}
                              {ticket.Price
                                ? toCurrency({ value: ticket.Price })
                                : null}
                            </div>
                            <DisplayMarkup markup={ticket.Description} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </Grid>
            ) : null}

            {data.Speakers.length || data.Accommodations.length ? (
              <Grid item xs={12}>
                {buyTicketsBanner}
              </Grid>
            ) : null}

            {data.Speakers.length ? (
              <Grid item xs={12}>
                <div id={sectionKeys.speakers} />
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  color="primary"
                  style={{ fontFamily: 'Oswald' }}
                >
                  Speakers
                </Typography>

                <Divider />

                <Grid container spacing={2}>
                  {orderBy(data.Speakers, 'OrderIndex').map((item) => {
                    return (
                      <Grid
                        key={item.SpeakerId}
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{ padding: 20 }}
                      >
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              marginBottom: 15,
                              height: 200,
                              width: 200,
                              borderRadius: 100,
                              backgroundColor: '#777',
                              backgroundImage: item.ImageName
                                ? `url(${item.ImageName})`
                                : 'none',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }}
                          />

                          <div style={{ flex: 1 }}>
                            <strong
                              style={{ fontSize: 18, fontFamily: 'Oswald' }}
                            >
                              {item.Title}
                            </strong>
                            <DisplayMarkup markup={item.Description} />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : null}

            {data.Accommodations.length ? (
              <Grid item xs={12}>
                <div id={sectionKeys.accomodations} />
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  color="primary"
                  style={{ fontFamily: 'Oswald' }}
                >
                  Hotel Information
                </Typography>

                <Divider />

                {orderBy(data.Accommodations, 'OrderIndex').map(
                  (item, index) => {
                    const isEven = index % 2 === 0;
                    let flexDirection: React.CSSProperties['flexDirection'] =
                      'column';
                    if (width >= 800) {
                      if (isEven) {
                        flexDirection = 'row-reverse';
                      } else {
                        flexDirection = 'row';
                      }
                    }
                    return (
                      <div
                        key={item.AccomId}
                        style={{
                          display: 'flex',
                          margin: 20,
                          flexDirection,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              height: 300,
                              margin: 20,
                              backgroundColor: '#777',
                              backgroundImage: item.ImageName
                                ? `url(${item.ImageName})`
                                : 'none',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 20,
                            }}
                          />
                        </div>

                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="h6" gutterBottom>
                            {item.Title}
                          </Typography>

                          <DisplayMarkup markup={item.Description} />
                        </div>
                      </div>
                    );
                  },
                )}
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Maps data={data.Maps} />
            </Grid>

            <Grid item xs={12}>
              {buyTicketsBanner}
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  } else {
    // Could not find event
    content = <EventNotFound />;
  }

  let routeTitle = '';
  if (query.isLoading) {
    routeTitle = '';
  } else if (data) {
    routeTitle = data.Title || '';
  }

  return (
    <RouteContainer
      routeTitle={routeTitle}
      noanimation
      loading={query.isLoading}
      hasError={query.isError}
    >
      {content}
    </RouteContainer>
  );
}
