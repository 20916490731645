import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { EventAgendaData } from '../types';
import { DisplayMarkup } from './display-markup';

const useStyles = makeStyles({
  agendaTabs: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #eaeaea',
  },
  agendaItem: {
    backgroundColor: '#fff',
    padding: 10,
    borderBottom: '1px solid #eaeaea',
  },
});

export function EventAgenda(props: { data: EventAgendaData[] }) {
  // Props
  const { data } = props;
  // State
  const [items, setItems] = React.useState<EventAgendaData[]>([]);
  const [days, setDays] = React.useState<string[]>([]);
  const [value, setValue] = React.useState(0);
  // Hooks
  const classes = useStyles();

  React.useEffect(() => {
    if (data.length) {
      const orderedItems = orderBy(data, 'DateTime');
      setItems(orderedItems);

      // Get the days for the Event's Agenda items
      const newDays: string[] = [];
      orderedItems.forEach((i, index) => {
        const Day = i.DateTime?.substring(0, 10) || '';
        if (Day !== '' && !newDays.includes(Day)) {
          newDays.push(Day);

          // Set the selected tab to the current day
          const currentDay = format(new Date(), 'yyyy-MM-dd');
          if (Day === currentDay) {
            const newValue = newDays.findIndex((i) => i === currentDay);
            setValue(newValue);
          }
        }
      });
      setDays(newDays);
    }
  }, [data]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // Get the agenda items for the selected day
  const dayItems = items.filter((i) => {
    const Day = i.DateTime?.substring(0, 10) || '';
    return Day !== '' && Day === days[value];
  });

  return (
    <div>
      <div className={classes.agendaTabs}>
        <Tabs
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons
          value={value}
          onChange={handleChange}
          allowScrollButtonsMobile
        >
          {days.map((day) => {
            const label = format(new Date(day + 'T00:00'), 'EEE MMM dd');
            return <Tab key={day} label={label} />;
          })}
        </Tabs>
      </div>

      <div>
        {dayItems.map((item) => {
          const itemEnded = item.DateTime
            ? isPast(new Date(item.DateTime))
            : true;

          return (
            <div
              key={item.AgendaId}
              className={classes.agendaItem}
              style={{ opacity: itemEnded ? 0.6 : 1 }}
            >
              <div>
                <strong style={{ fontSize: 16 }}>
                  {item.DateTime ? format(new Date(item.DateTime), 'p') : null}{' '}
                  - {item.Title}
                </strong>
                <DisplayMarkup markup={item.Description} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
