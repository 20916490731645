import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useParams } from 'react-router-dom';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { VideoFrame } from '../../components/video-frame';
import { EventData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formatEventData } from '../../utils/format-events';
import { Maps } from './maps';
import { TripNotFound } from './trip-not-found';

const useStyles = makeStyles((theme) => ({
  scrollButtons: {
    color: '#fff',
  },
  eventBanner: {
    width: '100%',
    height: 400,
    backgroundColor: '#777',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  eventBackground: {
    background: `linear-gradient(to right, #12121200, #12121299, #12121200)`,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  eventTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  eventTitle: {
    color: '#fff',
    textShadow: '2px 2px rgba(0,0,0,0.6)',
    fontWeight: 'bold',
    fontFamily: 'Oswald',
    textTransform: 'uppercase',
    letterSpacing: 1.1,
    textAlign: 'center',
    fontSize: 48,
    [theme.breakpoints.up('md')]: {
      fontSize: 64,
    },
  },
  eventPurchase: {
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: 60,
    },
  },
  tabItem: {
    fontSize: 16,
    fontFamily: 'Oswald',
  },
}));

type RouteParams = { tripId?: string };
export function Trip() {
  // Hooks
  const classes = useStyles();
  const params: RouteParams = useParams();

  // Query - Trip
  const path = '/events';
  const query = useQuery({
    queryKey: [
      path,
      {
        EventCode: params.tripId,
        EventStatus: ['Published'],
        EventType: ['Trip'],
        EventDetails: [{ activeTicketTypeOnly: 1 }],
      },
    ],
    queryFn: async () => {
      const response: {
        data: EventData[];
      } = await API.post('EventsAPI', path, {
        body: {
          EventCode: params.tripId,
          EventStatus: ['Published'],
          EventType: ['Trip'],
          EventDetails: [{ activeTicketTypeOnly: 1 }],
        },
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Trip data
  let data: EventData | undefined;
  if (query.data && query.data.length) {
    data = formatEventData({ data: query.data[0] });
  }

  let content = <div />;
  if (data) {
    let promoVideo: JSX.Element | null = null;
    if (data.VideoUrl) {
      promoVideo = (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ width: '100%', maxWidth: 960 }}>
            <VideoFrame src={data.VideoUrl} />
          </div>
        </Grid>
      );
    }

    content = (
      <React.Fragment>
        <div
          className={classes.eventBanner}
          style={{
            backgroundImage: data.BannerImageName
              ? `url(${data.BannerImageName})`
              : 'none',
          }}
        >
          <div className={classes.eventBackground}>
            <div>
              <div className={classes.eventTitleWrapper}>
                <div className={classes.eventTitle}>{data.Title}</div>
              </div>

              {data.DateBegin && data.DateEnd ? (
                <div style={{ marginTop: 5, textAlign: 'center' }}>
                  <div
                    style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}
                  >
                    {format(new Date(data.DateBegin), 'MMM d')} -{' '}
                    {format(new Date(data.DateEnd), 'PP')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Container style={{ marginTop: 20 }}>
          <Grid container spacing={4}>
            {promoVideo}

            <Grid item xs={12}>
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 10,
                  }}
                >
                  <Typography variant="h4" style={{ fontFamily: 'Oswald' }}>
                    {data.Title}
                  </Typography>
                </div>

                <Typography variant="body1">
                  in{' '}
                  {data.DateBegin
                    ? formatDistanceToNow(new Date(data.DateBegin))
                    : null}{' '}
                  from{' '}
                  {data.DateBegin
                    ? format(new Date(data.DateBegin), 'eee MMM do')
                    : null}{' '}
                  to{' '}
                  {data.DateEnd
                    ? format(new Date(data.DateEnd), 'eee MMM do yyyy')
                    : null}
                </Typography>

                <DisplayMarkup markup={data.Description} />
              </div>
            </Grid>

            {data.LocationDescription ? (
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="h4"
                  color="primary"
                  gutterBottom
                  style={{ fontFamily: 'Oswald' }}
                >
                  Location
                </Typography>

                <Divider />

                <div
                  style={{
                    margin: 20,
                    height: 260,
                    borderRadius: 10,
                    backgroundColor: '#777',
                    backgroundImage: data.LocationImageName
                      ? `url(${data.LocationImageName})`
                      : 'none',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                />

                <div>
                  <strong style={{ fontSize: 16 }}>{data.LocationTitle}</strong>
                  <DisplayMarkup markup={data.LocationDescription} />
                </div>
              </Grid>
            ) : null}

            {orderBy(data.Sections, 'OrderIndex').map((section) => {
              return (
                <Grid key={section.SecId} item xs={12} sm={6}>
                  <Typography
                    variant="h4"
                    color="primary"
                    gutterBottom
                    style={{ fontFamily: 'Oswald' }}
                  >
                    {section.Title}
                  </Typography>

                  <Divider />

                  {section.ImageName ? (
                    <div
                      style={{
                        margin: 20,
                        height: 260,
                        borderRadius: 10,
                        backgroundColor: '#777',
                        backgroundImage: section.ImageName
                          ? `url(${section.ImageName})`
                          : 'none',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }}
                    />
                  ) : null}

                  <DisplayMarkup markup={section.Description} />
                </Grid>
              );
            })}
          </Grid>

          <Maps data={data.Maps} />
        </Container>
      </React.Fragment>
    );
  } else {
    // Could not find event
    content = <TripNotFound />;
  }

  let routeTitle = '';
  if (query.isLoading) {
    routeTitle = '';
  } else if (data) {
    routeTitle = data.Title || '';
  }

  return (
    <RouteContainer
      routeTitle={routeTitle}
      loading={query.isLoading}
      hasError={query.isError}
    >
      {content}
    </RouteContainer>
  );
}
