import RoomIcon from '@mui/icons-material/Room';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import { InfoWindow, Marker } from '@react-google-maps/api';
import format from 'date-fns/format';
import React from 'react';
import HotspotIcon from '../../assets/hotspot.svg';
import { DisplayMarkup } from '../../components/display-markup';
import { HotspotData } from '../../types';

const useStyles = makeStyles({
  link: {
    marginTop: 6,
    padding: 6,
    display: 'flex',
    alignItems: 'center',
    color: '#4a4a4a',
    textDecorationLine: 'none',
    border: '1px solid #eee',
    borderRadius: 6,
    transition: 'all 0.2s',
    '&:hover': {
      color: '#2a2a2a',
      border: '1px solid #2979ff',
      backgroundColor: '#2979ff11',
    },
  },
});

export function MapHotspot(props: { meeting: HotspotData }) {
  // Props
  const { meeting } = props;
  // State
  const [open, setOpen] = React.useState(false);
  // Hooks
  const classes = useStyles();

  // Googls Maps URL with address query
  const mapsURL = `https://www.google.com/maps/search/?api=1&query=${meeting.Latitude},${meeting.Longitude}`;

  if (meeting.Latitude && meeting.Longitude) {
    return (
      <Marker
        position={{ lat: meeting.Latitude, lng: meeting.Longitude }}
        icon={HotspotIcon}
        onClick={() => setOpen((currentState) => !currentState)}
      >
        {open && (
          <InfoWindow
            position={{ lat: meeting.Latitude, lng: meeting.Longitude }}
            onCloseClick={() => setOpen(false)}
          >
            <Stack spacing={1}>
              <Stack spacing={1} direction="row" alignItems="center">
                <Box sx={{ flex: 1, borderTop: '1px solid #eaeaea' }} />
                <Box sx={{ fontFamily: 'Oswald', fontSize: 18 }}>
                  Hotspot meeting
                </Box>
                <Box sx={{ flex: 1, borderTop: '1px solid #eaeaea' }} />
              </Stack>

              {meeting.DateTime ? (
                <Box sx={{ fontWeight: 'bold', fontSize: 16 }}>
                  {format(new Date(meeting.DateTime), "EEEE, MMMM do 'at' pp")}
                </Box>
              ) : null}

              <Stack spacing={1} direction="row" alignItems="center">
                {meeting.HostImage ? (
                  <Box
                    sx={{
                      height: 64,
                      width: 64,
                      borderRadius: 2,
                      backgroundImage: `url(${meeting.HostImage})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  />
                ) : null}

                <Box>
                  <Box sx={{ fontWeight: 'bold', fontSize: 20 }}>
                    {meeting.HostName}
                  </Box>
                  <Box sx={{ fontSize: 18 }}>at {meeting.Location}</Box>
                </Box>
              </Stack>

              <Box sx={{ maxWidth: 320 }}>
                <DisplayMarkup markup={meeting.Description} />
              </Box>

              <a
                href={mapsURL}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <RoomIcon />

                <strong style={{ marginLeft: 8 }}>Open in Google Maps</strong>
              </a>
            </Stack>
          </InfoWindow>
        )}
      </Marker>
    );
  } else {
    return null;
  }
}
