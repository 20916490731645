import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React from 'react';

export function CardIcon(props: {
  cardType: string;
  size?: FontAwesomeIconProps['size'];
}) {
  let icon: FontAwesomeIconProps['icon'] = faCreditCard;

  const cardType = props.cardType.toLowerCase();

  if (cardType === 'visa') {
    icon = faCcVisa;
  } else if (cardType === 'mastercard') {
    icon = faCcMastercard;
  } else if (cardType === 'americanexpress') {
    icon = faCcAmex;
  } else if (cardType === 'discover') {
    icon = faCcDiscover;
  } else if (cardType === 'jcb') {
    icon = faCcJcb;
  } else if (cardType === 'dinersclub') {
    icon = faCcDinersClub;
  }

  return <FontAwesomeIcon icon={icon} size={props.size} color="#4a4a4a" />;
}
