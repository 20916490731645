import Button, { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { EventData } from '../types';

export function PurchaseTickets(props: {
  data: EventData;
  buttonText?: string;
  size?: ButtonProps['size'];
}) {
  return (
    <Button
      size={props.size}
      disableElevation
      variant="contained"
      color="secondary"
      component={Link}
      to={`/events/${props.data.EventCode}/register`}
    >
      {props.buttonText || 'Buy Tickets'}
    </Button>
  );
}
