import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link } from 'react-router-dom';
import { DisplayMarkup } from '../../components/display-markup';
import { PurchaseTickets } from '../../components/purchase-tickets';
import { RouteContainer } from '../../components/route-container';
import { AppContext } from '../../contexts/app-context';
import { CarouselData, EventData, ExperienceData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formatEventsData } from '../../utils/format-events';
import { HomeCarousel } from './home-carousel';

const useStyles = makeStyles({
  description: {
    fontSize: 18,
    '& a': {
      color: red[500],
      textDecorationLine: 'none',
    },
  },
});

export function Home() {
  // Context
  const { state } = React.useContext(AppContext);
  // Hooks
  const width = useWindowWidth();
  const classes = useStyles();

  // Query - Carousel
  const pathCarousel = '/carousel';
  const queryCarousel = useQuery({
    queryKey: [pathCarousel],
    queryFn: async () => {
      const response: {
        data: CarouselData[];
      } = await API.post('EventsAPI', pathCarousel, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Experiences
  const pathExperiences = '/experiences';
  const queryExperiences = useQuery({
    queryKey: [pathExperiences],
    queryFn: async () => {
      const response: {
        data: ExperienceData[];
      } = await API.post('EventsAPI', pathExperiences, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Trips
  const pathEvents = '/events';
  const queryEvents = useQuery({
    queryKey: [pathEvents, { EventType: ['Trip'], EventStatus: ['Published'] }],
    queryFn: async () => {
      const response: {
        data: EventData[];
      } = await API.post('EventsAPI', pathEvents, {
        body: { EventType: ['Trip'], EventStatus: ['Published'] },
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const slides = orderBy(queryCarousel.data || [], 'CIndex');
  const experiences = orderBy(queryExperiences.data || [], 'EIndex');
  const trips = formatEventsData({ data: queryEvents.data || [] });

  // Upcoming Events
  let upcoming: EventData[] = [];
  const eventsData = [...state.eventsData, ...trips];
  const eventsOrdered = orderBy(eventsData, 'DateBegin');
  if (eventsOrdered.length > 1) {
    upcoming = [eventsOrdered[0], eventsOrdered[1]];
  } else if (eventsOrdered.length === 1) {
    upcoming = [eventsOrdered[0]];
  }

  const isLoading =
    queryCarousel.isLoading ||
    queryExperiences.isLoading ||
    queryEvents.isLoading;

  return (
    <RouteContainer
      routeTitle="Home"
      loading={isLoading || state.eventsLoading}
      notransform={'true'}
    >
      <HomeCarousel data={slides} />

      <Container>
        {upcoming.map((item, index) => {
          const isEven = index % 2 === 0;
          let flexDirection: React.CSSProperties['flexDirection'] = 'column';
          if (width >= 800) {
            if (isEven) {
              flexDirection = 'row';
            } else {
              flexDirection = 'row-reverse';
            }
          }

          // Buy Tickets or Learn More
          let linkTo = '/';
          let linkButton: JSX.Element | null = null;
          if (item.EventType === 'Trip') {
            linkTo = `/trips/${item.EventCode}`;
            linkButton = (
              <Button
                size="small"
                disableElevation
                variant="contained"
                color="secondary"
                component={Link}
                to={linkTo}
              >
                Learn more
              </Button>
            );
          } else if (item.EventType === 'Conference') {
            linkTo = `/events/${item.EventCode}`;
            linkButton = <PurchaseTickets data={item} size="small" />;
          }

          return (
            <div
              key={item.EventCode}
              style={{
                display: 'flex',
                margin: 10,
                flexDirection,
              }}
            >
              <div style={{ flex: 1, padding: 10 }}>
                <Link
                  to={linkTo}
                  style={{ display: 'block', textDecorationLine: 'none' }}
                >
                  <div
                    style={{
                      height: 240,
                      backgroundColor: '#777',
                      backgroundImage: item.BannerImageName
                        ? `url(${item.BannerImageName})`
                        : 'none',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 20,
                    }}
                  >
                    <h2
                      style={{
                        color: '#fff',
                        textShadow: '2px 2px rgba(0,0,0,0.6)',
                        fontSize: 48,
                        fontFamily: 'Oswald',
                        textTransform: 'uppercase',
                        letterSpacing: 1.1,
                        textAlign: 'center',
                      }}
                    >
                      {item.Title}
                    </h2>
                  </div>
                </Link>
              </div>

              <div style={{ flex: 1, padding: 10 }}>
                <div style={{ marginBottom: 20 }}>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{ fontSize: 18 }}
                  >
                    <DisplayMarkup markup={item.Description} />
                  </Typography>
                </div>

                {linkButton}
              </div>
            </div>
          );
        })}

        <Typography variant="h2" component="h2" align="center">
          The Experience
        </Typography>

        {experiences.map((item, index) => {
          const isEven = index % 2 === 0;
          let flexDirection: React.CSSProperties['flexDirection'] = 'column';
          if (width >= 800) {
            if (isEven) {
              flexDirection = 'row';
            } else {
              flexDirection = 'row-reverse';
            }
          }
          return (
            <div
              key={item.ExpId}
              style={{
                display: 'flex',
                margin: 10,
                flexDirection,
              }}
            >
              <div style={{ flex: 1, padding: 10 }}>
                <div
                  style={{
                    height: 240,
                    backgroundColor: '#777',
                    backgroundImage: item.ImageName
                      ? `url(${item.ImageName})`
                      : 'none',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 20,
                  }}
                />
              </div>

              <div style={{ flex: 1, padding: 10 }}>
                <Typography variant="h6" component="h3" gutterBottom>
                  {item.Title}
                </Typography>

                <DisplayMarkup
                  markup={item.Description}
                  className={classes.description}
                />
              </div>
            </div>
          );
        })}
      </Container>
    </RouteContainer>
  );
}
