import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  GoogleMap,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { AnimateIn } from '../../components/animate-in';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { CRMAgent, HotspotData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { sumArray } from '../../utils/sumarray';
import { MapHotspot } from './map-hotspot';
import { MapMarker } from './map-marker';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: grey[900],
    borderBottom: `3px solid ${red[700]}`,
    borderRadius: '5px 5px 0px 0px',
  },
  content: {
    flex: 1,
    padding: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: 750,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  contentText: {
    flex: 1,
    marginTop: 10,
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: 20,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      borderBottom: `1px solid ${grey[700]}`,
      borderTop: `1px solid ${grey[700]}`,
      marginTop: 20,
      paddingTop: 15,
      paddingBottom: 20,
    },
  },
  speakerImage: {
    height: 200,
    width: 200,
    borderRadius: 100,
    backgroundColor: '#777',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: '3px 3px 3px grey',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      borderRight: `1px solid ${grey[700]}`,
      paddingRight: 25,
    },
  },
  paper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 15,
  },
  markup: {
    '& p': {
      margin: 0,
    },
  },
}));

export function Hotspots() {
  // Context
  const {
    state: { user },
  } = React.useContext(AuthContext);
  // Ref
  const mapRef = React.useRef<GoogleMap>(null);
  // State
  const [loading, setLoading] = React.useState(true);
  const [userLocation, setUserLocation] =
    React.useState<GeolocationCoordinates>();
  // Hooks
  const classes = useStyles();

  // Get user's location
  React.useEffect(() => {
    const getCurrentPosition = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (successCallback) => {
            resolve(successCallback);
          },
          (errorCallback) => {
            reject(errorCallback);
          }
        );
      });
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        // Alliance Convention Center
        const defaultCoordinates: GeolocationCoordinates = {
          accuracy: 10,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: 36.07813,
          longitude: -79.432279,
          speed: null,
        };

        if ('geolocation' in navigator) {
          // Coordinates
          try {
            const position =
              (await getCurrentPosition()) as GeolocationPosition;
            setUserLocation(position.coords);
          } catch (error) {
            // Permission was denied
            setUserLocation(defaultCoordinates);
          }
        } else {
          /* geolocation IS NOT available */
          setUserLocation(defaultCoordinates);
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Query - Hotspots
  const pathHotspots = '/meetings';
  const queryHotspots = useQuery({
    queryKey: [pathHotspots],
    queryFn: async () => {
      const response: {
        data: HotspotData[];
      } = await API.post('DashboardAPI', pathHotspots, {});

      if (response.data) {
        return response.data.map((meeting) => ({
          ...meeting,
          Latitude: Number(meeting.Latitude),
          Longitude: Number(meeting.Longitude),
        }));
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Agents
  const pathAgents = '/crm/agents';
  const queryAgents = useQuery({
    enabled: isAgt(user?.getUsername()),
    queryKey: [pathAgents],
    queryFn: async () => {
      const response: {
        data: CRMAgent[];
      } = await API.post('DashboardAPI', '/crm/agents', {
        body: { excludeInactive: true },
      });

      if (response.data) {
        return response.data.map((agent) => ({
          ...agent,
          Latitude: Number(agent.Latitude),
          Longitude: Number(agent.Longitude),
        }));
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
  });

  // Hotspot Meetings
  const dataHotspots = queryHotspots.data
    ? orderBy(queryHotspots.data, 'DateTime', 'asc')
    : [];
  // Hotspot Markers
  const displayHotspots = dataHotspots.filter(
    (i) => i.Longitude && i.Longitude !== 0 && i.Latitude && i.Latitude !== 0
  );

  // Agents will only load if the logged in user is an Agent
  const isLoadingAgents =
    queryAgents.isLoading && queryAgents.fetchStatus !== 'idle';
  // Active Agents with coordinates
  const dataAgents = queryAgents.data
    ? queryAgents.data.filter(
        (i) =>
          i.AgentStatus === 'A' &&
          i.Longitude &&
          i.Longitude !== 0 &&
          i.Latitude &&
          i.Latitude !== 0
      )
    : [];

  // Coordinates for centering the map
  // Default to coordinates for the Alliance Convention Center
  let centerLat = 36.07813;
  let centerLng = -79.432279;
  if (userLocation) {
    // User granted permission for their location
    centerLat = userLocation.latitude;
    centerLng = userLocation.longitude;
  } else if (dataAgents.length) {
    // Average coordinates for all Agents
    centerLat =
      sumArray(dataAgents.map((coord) => coord.Latitude || 0)) /
      dataAgents.length;
    centerLng =
      sumArray(dataAgents.map((coord) => coord.Longitude || 0)) /
      dataAgents.length;
  } else if (dataHotspots.length) {
    // Average coordinates for all Hotspots
    centerLat =
      sumArray(dataHotspots.map((coord) => coord.Latitude || 0)) /
      dataHotspots.length;
    centerLng =
      sumArray(dataHotspots.map((coord) => coord.Longitude || 0)) /
      dataHotspots.length;
  }

  return (
    <RouteContainer
      routeTitle="Hotspots"
      loading={queryHotspots.isLoading}
      hasError={queryHotspots.isError}
      noanimation
    >
      <div>
        <Box sx={{ height: '50dvh', backgroundColor: '#E6E3E0' }}>
          {isLoaded &&
          !queryHotspots.isLoading &&
          !isLoadingAgents &&
          !loading ? (
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={{ height: '100%', width: '100%' }}
              zoom={7}
              options={{
                fullscreenControl: false,
                zoomControlOptions: {
                  position: 6,
                },
                streetViewControlOptions: {
                  position: 6,
                },
                mapTypeControlOptions: {
                  position: 3,
                },
              }}
              center={{ lat: centerLat, lng: centerLng }}
            >
              <MarkerClusterer minimumClusterSize={3} maxZoom={12}>
                {(clusterer) => (
                  <React.Fragment>
                    {dataAgents.map((data, index) => {
                      const key = data.AgentNumber || index;
                      return (
                        <MapMarker
                          key={key}
                          contact={data}
                          clusterer={clusterer}
                        />
                      );
                    })}
                  </React.Fragment>
                )}
              </MarkerClusterer>

              {displayHotspots.map((meeting) => {
                return <MapHotspot key={meeting.MeetingId} meeting={meeting} />;
              })}
            </GoogleMap>
          ) : (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="info" size={64} />
            </Box>
          )}
        </Box>

        <Divider />

        <AnimateIn to="top">
          <Container maxWidth="md" sx={{ pt: 2 }}>
            <Stack spacing={2}>
              <Paper sx={{ p: 1, textAlign: 'center' }}>
                <Stack spacing={1}>
                  <Box
                    sx={{
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    Join us across the country to share what The Alliance
                    represents!
                  </Box>
                  <Box>
                    Bring potential agents and recruits to learn how we have
                    fun, make money, and make a difference! Hear from top
                    leaders' experiences and insight! Discover how to build your
                    business and team by getting the training you need from
                    those who are already doing it!
                  </Box>
                </Stack>
              </Paper>

              {dataHotspots.map((item) => {
                const MeetingTime = item.DateTime?.substring(0, 19);
                return (
                  <Paper key={item.MeetingId}>
                    <Toolbar variant="dense" className={classes.toolbar}>
                      <Typography
                        variant="h5"
                        style={{
                          textAlign: 'center',
                          color: '#fff',
                          fontFamily: 'Oswald',
                        }}
                      >
                        {format(new Date(item.DateTime!), `LLLL do, yyyy`)} -{' '}
                        {item.Location} @
                        {format(new Date(MeetingTime!), `h:mmaaa`)}
                      </Typography>
                    </Toolbar>
                    <div className={classes.content}>
                      <div className={classes.imageContainer}>
                        <div
                          className={classes.speakerImage}
                          style={{
                            backgroundImage: item.HostImage
                              ? `url(${item.HostImage})`
                              : 'none',
                          }}
                        />
                      </div>

                      <div className={classes.contentText}>
                        <Typography
                          variant="h5"
                          style={{ fontFamily: 'Oswald' }}
                        >
                          {item.HostName}
                        </Typography>

                        {item.Description && (
                          <div style={{ marginTop: 5 }}>
                            <DisplayMarkup
                              markup={item.Description}
                              className={classes.markup}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Paper>
                );
              })}
            </Stack>
          </Container>
        </AnimateIn>
      </div>
    </RouteContainer>
  );
}
