import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { API, Auth } from 'aws-amplify';
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Arrival } from '../components/arrival';
import { MaintenanceMessage } from '../components/maintenance-message';
import { MFACheck } from '../components/mfa-check';
import { Navigation } from '../components/navigation';
import { TokenError } from '../components/token-error';
import { AppContext } from '../contexts/app-context';
import { AuthContext } from '../contexts/auth-context';
import { EventData } from '../types';
import { captureError } from '../utils/capture-error';
import { formatEventsData } from '../utils/format-events';
import { checkForRedirectPath } from '../utils/redirect';

export function Root() {
  // Context
  const { state } = React.useContext(AuthContext);
  const { dispatch } = React.useContext(AppContext);
  // State
  const [checking, setChecking] = React.useState(true);
  const [hasChecked, setHasChecked] = React.useState(false);
  const [maintData, setMaintData] = React.useState({
    checking: true,
    maintenanceActive: false,
    maintenanceBegin: '',
    maintenanceEnd: '',
  });
  // Hooks
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkForMaintenance = async () => {
      try {
        if (process.env.REACT_APP_MAINT_URL) {
          const body = { data: { origin: window.origin } };
          const response = await fetch(process.env.REACT_APP_MAINT_URL, {
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
          });
          const data: {
            data: {
              maintenanceActive: boolean;
              maintenanceBegin: string;
              maintenanceEnd: string;
            };
          } = await response.json();
          setMaintData((currentState) => ({ ...currentState, ...data.data }));
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setMaintData((currentState) => ({ ...currentState, checking: false }));
      }
    };

    // Check for the path after the authentication context is done checking
    // if the user is signed in
    if (!state.isChecking) {
      checkForMaintenance();
    }
  }, [state.isChecking]);

  React.useEffect(() => {
    const checkForPath = async () => {
      try {
        setChecking(true);
        setHasChecked(true);
        // Check if there is a path stored in the browser to
        // redirect the user to after logging in
        const pathname = await checkForRedirectPath();
        if (pathname) {
          // Route the user to the path
          navigate(pathname);
        }
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setChecking(false);
      }
    };

    // Check for the path after the authentication context is done checking
    // if the user is signed in
    if (!state.isChecking && !hasChecked) {
      checkForPath();
    }
  }, [navigate, state.isChecking, hasChecked]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const responseEvents: {
          data: EventData[];
        } = await API.post('EventsAPI', '/events', {
          body: { EventType: ['Conference'], EventStatus: ['Published'] },
        });
        const eventsData = formatEventsData({ data: responseEvents.data });
        dispatch({ type: 'SET_EVENTS', payload: eventsData });
      } catch (error) {
        captureError({ data: { error } });
      }
    };

    fetchData();
  }, [dispatch]);

  const isAuthenticated = state.user !== undefined;

  // Query - Check that the user has set up Multi-Factor Authentication for their account
  const queryMFA = useQuery({
    enabled: isAuthenticated,
    queryKey: ['check_Auth.getPreferredMFA'],
    queryFn: async () => {
      // Bypass Cache to always get the latest preferences for the Current User
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const preferredMFA = await Auth.getPreferredMFA(currentUser);

      const mfaOptions = ['EMAIL_OTP', 'SMS_MFA', 'SOFTWARE_TOKEN_MFA'];

      // Determine if the user has selected one of the available options
      return mfaOptions.includes(preferredMFA || '');
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isLoadingMFA = queryMFA.isLoading && queryMFA.fetchStatus !== 'idle';

  const isLoading = state.isChecking || checking || maintData.checking;

  if (isLoading || isLoadingMFA) {
    return <LinearProgress color="primary" />;
  } else if (!queryMFA.data && isAuthenticated) {
    return <MFACheck />;
  } else if (maintData.maintenanceActive) {
    // Display a Maintenance Message
    return <MaintenanceMessage maintenanceEnd={maintData.maintenanceEnd} />;
  } else {
    return (
      <div>
        <Navigation />

        <TokenError />

        <Outlet />

        <Arrival />
      </div>
    );
  }
}
