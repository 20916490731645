import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import React from 'react';
import { CustomerProfileIframe } from '../../components/customer-profile-iframe';
import { CustomerData, CustomerPaymentData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function RegisterPaymentAdd(props: {
  onUpdateBilling: (
    data: CustomerData,
    customerPaymentProfileId?: CustomerPaymentData['customerPaymentProfileId']
  ) => void;
  isAgent?: boolean;
  // Guest email
  email?: string;
  // Agent button settings
  buttonLarge?: boolean;
  buttonText?: string;
}) {
  // State
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState('');
  const [customerProfileId, setCustomerProfileId] = React.useState('');

  React.useEffect(() => {
    // Reset State
    setLoading(true);
    setToken('');
    setCustomerProfileId('');
  }, [open]);

  const getAuthorizeToken = async () => {
    try {
      setLoading(true);

      const hostedIframeReturnUrl =
        window.location.origin + '/IFrameCommunicator.html';

      if (props.isAgent) {
        // Get a token for an agent allowing them to add payment information
        const response: { token: string } = await API.post(
          'EventsAPI',
          '/events/payments/customer-profile/token',
          { body: { hostedIframeReturnUrl } }
        );
        setToken(response.token);
      } else {
        // Create a profile for a guest
        const responseProfile: { customerProfileId: string } = await API.post(
          'EventsAPI',
          '/events/payments/customer-profile/create-guest',
          { body: { email: props.email } }
        );
        setCustomerProfileId(responseProfile.customerProfileId);

        // Get a token for a guest allowing them to add payment information
        const responseToken: { token: string } = await API.post(
          'EventsAPI',
          '/events/payments/customer-profile/token',
          {
            body: {
              customerProfileId: responseProfile.customerProfileId,
              hostedIframeReturnUrl,
            },
          }
        );
        setToken(responseToken.token);
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setLoading(false);
    }
  };

  const getPaymentData = async () => {
    try {
      setLoading(true);
      const response: { data: CustomerData } = await API.post(
        'EventsAPI',
        '/events/payments/customer-profile',
        { body: props.isAgent ? {} : { customerProfileId } }
      );
      props.onUpdateBilling(response.data, customerProfileId);
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setLoading(false);
    }
  };

  const handleRefetchAndClose = async () => {
    if (!loading) {
      await getPaymentData();
      setOpen(false);
    }
  };

  const handleClickOpen = async () => {
    setOpen(true);
    await getAuthorizeToken();
  };

  let dialogContent = <div />;
  if (loading) {
    dialogContent = (
      <div style={{ padding: 40 }}>
        <LinearProgress />
      </div>
    );
  } else if (token) {
    dialogContent = (
      <DialogContent>
        <CustomerProfileIframe
          action="/customer/addPayment"
          iframeToken={token}
          title="Add Card"
          onReceiveCommunication={(action) => {
            if (action) {
              handleRefetchAndClose();
            }
          }}
        />
      </DialogContent>
    );
  }

  return (
    <React.Fragment>
      {props.isAgent ? (
        <Button
          size={props.buttonLarge ? 'large' : 'small'}
          fullWidth={props.buttonLarge}
          color={props.buttonLarge ? 'primary' : 'inherit'}
          variant="contained"
          disableElevation
          onClick={handleClickOpen}
        >
          {props.buttonText || 'Add Card'}
        </Button>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Button
            disabled={!props.email}
            size="large"
            variant="contained"
            disableElevation
            onClick={handleClickOpen}
            style={{ width: '100%', maxWidth: 400 }}
          >
            Open payment information form
          </Button>
        </div>
      )}

      <Dialog
        onClose={handleRefetchAndClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="add-payment-method"
        open={open}
      >
        <DialogTitle
          id="add-payment-method"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Add Card</Typography>
          <IconButton
            disabled={loading}
            size="small"
            onClick={handleRefetchAndClose}
            aria-label="close form"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {dialogContent}
      </Dialog>
    </React.Fragment>
  );
}
