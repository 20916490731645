import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import orderBy from 'lodash/orderBy';
import { AnimateIn } from '../../components/animate-in';
import { DisplayMarkup } from '../../components/display-markup';
import { EventRegistrationData, EventTicketData } from '../../types';
import { toCurrency } from '../../utils/formatter';

const useSyles = makeStyles((theme) => ({
  ticket: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #eee',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    transition: 'all 0.2s',
    '&:hover': {
      border: '1px solid #aaa',
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  ticketPrice: {
    marginLeft: 5,
    fontWeight: 'bold',
    fontSize: 26,
    color: '#4a4a4a',
  },
  ticketTitle: {
    borderLeft: `4px solid ${red[700]}`,
    padding: 4,
    fontSize: 22,
    fontWeight: 'bold',
  },
}));

export function RegisterTicketSelect(props: {
  data: EventRegistrationData;
  TicketTypeId: number;
  onSelect: (ticket: EventTicketData) => void;
}) {
  // Hooks
  const classes = useSyles();

  return (
    <div>
      <Typography variant="h6" component="div" gutterBottom>
        Ticket Type
      </Typography>

      <AnimateIn to="left">
        {orderBy(props.data.TicketTypes, 'Price').map((ticket) => {
          const isSelected = ticket.TicketTypeId === props.TicketTypeId;

          return (
            <div
              key={ticket.TicketTypeId}
              className={classes.ticket}
              style={{ backgroundColor: isSelected ? '#eee' : '#fff' }}
              onClick={() => props.onSelect(ticket)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={isSelected}
                  inputProps={{ 'aria-label': 'select ticket' }}
                />

                {ticket.Price !== null ? (
                  <div className={classes.ticketPrice}>
                    {ticket.Price === 0
                      ? 'Free'
                      : toCurrency({ value: ticket.Price })}
                  </div>
                ) : null}
              </div>

              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, marginLeft: 20 }}>
                  <div className={classes.ticketTitle}>{ticket.Title}</div>
                  <DisplayMarkup markup={ticket.Description} />
                </div>
              </div>
            </div>
          );
        })}
      </AnimateIn>
    </div>
  );
}
