import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import { EventRegistrationData } from '../../types';
import { toCurrency } from '../../utils/formatter';

export function SummaryPaymentSchedule(props: {
  data: EventRegistrationData;
  payToday: number;
  displayIntervals: boolean;
  intervalsToEvent: number;
  amountPerInterval: number;
}) {
  // Props
  const {
    data,
    payToday,
    displayIntervals,
    intervalsToEvent,
    amountPerInterval,
  } = props;

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 300,
        backgroundColor: '#efefef',
        borderRadius: 8,
        padding: 8,
      }}
    >
      <strong style={{ textTransform: 'uppercase' }}>Today</strong>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: 4,
        }}
      >
        <div style={{ fontFamily: 'monospace' }}>
          {format(new Date(), 'MMM do')}
        </div>
        <div
          style={{
            flex: 1,
            height: 1,
            backgroundColor: '#00000033',
            marginLeft: 10,
            marginRight: 10,
          }}
        />
        <strong style={{ fontFamily: 'monospace' }}>
          {toCurrency({ value: payToday })}
        </strong>
      </div>

      {displayIntervals ? (
        <div>
          <strong style={{ textTransform: 'uppercase' }}>
            Auto Payments (Estimated)
          </strong>

          {Array.from({ length: intervalsToEvent }).map((_, index) => {
            const nextMonth =
              format(addMonths(new Date(), index + 1), 'MMM') + ' 1st';

            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 4,
                }}
              >
                <div style={{ fontFamily: 'monospace' }}>{nextMonth}</div>
                <div
                  style={{
                    flex: 1,
                    height: 1,
                    backgroundColor: '#00000033',
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
                <strong style={{ fontFamily: 'monospace' }}>
                  {toCurrency({ value: amountPerInterval })}
                </strong>
              </div>
            );
          })}
        </div>
      ) : null}

      <strong style={{ textTransform: 'uppercase' }}>Event</strong>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: 4,
        }}
      >
        <div style={{ fontFamily: 'monospace' }}>
          {data.DateBegin ? format(new Date(data.DateBegin), 'MMM do') : null}
        </div>
        <div
          style={{
            flex: 1,
            height: 1,
            backgroundColor: '#00000033',
            marginLeft: 10,
            marginRight: 10,
          }}
        />
        <div style={{ fontFamily: 'monospace' }}>
          {data.DateEnd ? format(new Date(data.DateEnd), 'MMM do') : null}
        </div>
      </div>
    </div>
  );
}
