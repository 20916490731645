import LinearProgress from '@mui/material/LinearProgress';
import { Auth, Hub } from 'aws-amplify';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { RedirectSignIn } from './components/redirect-sign-in';
import { AppProvider } from './contexts/app-context';
import { AuthContext } from './contexts/auth-context';
import { usePermissions } from './hooks/use-permissions';
import { Event } from './routes/event';
import { Events } from './routes/events';
import { Home } from './routes/home';
import { Hotspots } from './routes/hotspots';
import { Register } from './routes/register';
import { Root } from './routes/root';
import { Trip } from './routes/trip';
import { Trips } from './routes/trips';
import { AWS_GROUP } from './types';

const AppRoutes = {
  Account: React.lazy(() => import('./routes/account/account-routes')),
  Admin: React.lazy(() => import('./routes/admin/admin-routes')),
  Ticket: React.lazy(() => import('./routes/ticket')),
  Print: React.lazy(() => import('./routes/print')),
  Registration: React.lazy(() => import('./routes/registration')),
  Waiver: React.lazy(() => import('./routes/waiver')),
};

export function App() {
  // Context
  const { state, dispatch } = React.useContext(AuthContext);
  // Hooks
  const { checkPermissions } = usePermissions();

  React.useEffect(() => {
    // Amplify has a local eventing system called Hub
    // Amplify’s Auth category publishes in the auth channel
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          dispatch({ type: 'USER_SET', payload: data });
          break;
        case 'tokenRefresh_failure':
          dispatch({ type: 'TOKEN_ERROR' });
          break;
        case 'signOut':
          dispatch({ type: 'USER_CLEAR' });
          break;
      }
    });

    const checkForUser = async () => {
      try {
        dispatch({ type: 'SET_CHECKING', payload: true });

        // Used to check if a user is logged in when the page is loaded
        const user = await Auth.currentAuthenticatedUser();
        dispatch({ type: 'USER_SET', payload: user });

        // Provides decoded JWT Tokens
        const userSession = await Auth.currentSession();
        dispatch({ type: 'SESSION_SET', payload: userSession });
      } catch (error) {
        // Will throw an error if there is no user logged in
        dispatch({ type: 'USER_CLEAR' });
      } finally {
        dispatch({ type: 'SET_CHECKING', payload: false });
      }
    };

    checkForUser();
  }, [dispatch]);

  const isAuthenticated = state.user !== undefined;
  const isAdmin = checkPermissions([AWS_GROUP.AllianceEvents_Admin]);

  const navigateHome = <Navigate replace to="/" />;

  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Root />}>
            <Route index element={<Home />} />
            <Route path="events/:eventId/register" element={<Register />} />
            <Route path="events/:eventId" element={<Event />} />
            <Route path="events" element={<Events />} />
            <Route path="trips/:tripId" element={<Trip />} />
            <Route path="trips" element={<Trips />} />
            <Route path="hotspots" element={<Hotspots />} />
            <Route
              path="ticket/:ticketId"
              element={
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Ticket />
                </React.Suspense>
              }
            />
            <Route
              path="waiver"
              element={
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Waiver />
                </React.Suspense>
              }
            />
            <Route
              path="registration"
              element={
                <React.Suspense fallback={<LinearProgress />}>
                  <AppRoutes.Registration />
                </React.Suspense>
              }
            />
            <Route
              path="account/*"
              element={
                isAuthenticated ? (
                  <React.Suspense fallback={<LinearProgress />}>
                    <AppRoutes.Account />
                  </React.Suspense>
                ) : (
                  <RedirectSignIn />
                )
              }
            />
            <Route
              path="admin/*"
              element={
                isAdmin ? (
                  <React.Suspense fallback={<LinearProgress />}>
                    <AppRoutes.Admin />
                  </React.Suspense>
                ) : isAuthenticated ? (
                  <Navigate replace to="/account" />
                ) : (
                  <RedirectSignIn />
                )
              }
            />
            <Route
              path="print"
              element={
                isAdmin ? (
                  <React.Suspense fallback={<LinearProgress />}>
                    <AppRoutes.Print />
                  </React.Suspense>
                ) : isAuthenticated ? (
                  <Navigate replace to="/" />
                ) : (
                  <RedirectSignIn />
                )
              }
            />
            <Route path="*" element={navigateHome} />
          </Route>
        </Routes>
      </Router>
    </AppProvider>
  );
}
