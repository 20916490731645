import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { DisplayMarkup } from '../../components/display-markup';
import { EventRegistrationData } from '../../types';

export function RegisterTermsAccept(props: {
  data: EventRegistrationData;
  accepted: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <div style={{ padding: 10 }}>
      <Typography variant="h6" component="div">
        Terms and Conditions
      </Typography>

      <div
        style={{
          height: 300,
          padding: 10,
          border: '1px solid #00000011',
          borderRadius: 4,
          overflow: 'auto',
        }}
      >
        <DisplayMarkup markup={props.data.TermsAndConditions} />
      </div>

      <div style={{ textAlign: 'center', marginTop: 5 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.accepted}
              onChange={(event, checked) => props.onChange(checked)}
              style={{ marginRight: 5 }}
            />
          }
          label="I agree to the terms and conditions"
        />
      </div>
    </div>
  );
}
