import EventIcon from '@mui/icons-material/Event';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export function EventNotFound() {
  return (
    <Container style={{ paddingTop: 20 }}>
      <Paper elevation={0}>
        <div style={{ textAlign: 'center', padding: 10 }}>
          <div
            style={{
              height: 80,
              width: 80,
              borderRadius: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px auto',
              backgroundColor: '#f1f1f1',
            }}
          >
            <EventIcon fontSize="large" color="primary" />
          </div>

          <Typography variant="h5" style={{ margin: '40px auto' }}>
            This event could not be found
          </Typography>

          <Typography style={{ margin: '40px auto' }}>
            <Link to="/events">View upcoming events</Link>
          </Typography>
        </div>
      </Paper>
    </Container>
  );
}
