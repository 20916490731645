import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { EventRegistrationData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { EventNotFound } from '../event/event-not-found';
import { RegisterAgent } from './register-agent';
import { RegisterGuest } from './register-guest';

type RouteParams = { eventId?: string };
export function Register() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [data, setData] = React.useState<EventRegistrationData>();
  // Hooks
  const params: RouteParams = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response: {
          data: EventRegistrationData;
        } = await API.post('EventsAPI', '/events/registration/info', {
          body: { EventCode: params.eventId },
        });
        const DateBegin = response.data.DateBegin
          ? response.data.DateBegin.substring(0, 19)
          : null;
        const DateEnd = response.data.DateEnd
          ? response.data.DateEnd.substring(0, 19)
          : null;
        setData({ ...response.data, DateBegin, DateEnd });
      } catch (error: any) {
        if (error?.response?.data?.errors === 'Could not find this event.') {
          // Do nothing and the content will default to Event Not Found
          return;
        } else {
          // Notify the user of an error and log it
          setHasError(true);
          captureError({ data: { error } });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params.eventId]);

  let content = <div />;
  if (data) {
    let registerForm = <div />;
    if (data.TicketTypes.length === 0) {
      registerForm = (
        <div style={{ textAlign: 'center', padding: 10 }}>
          <div
            style={{
              height: 80,
              width: 80,
              borderRadius: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '40px auto',
              backgroundColor: '#f1f1f1',
            }}
          >
            <ConfirmationNumberIcon fontSize="large" color="primary" />
          </div>

          <Typography variant="h5" style={{ margin: '40px auto' }}>
            No tickets have been created for this event yet.
          </Typography>

          <Typography style={{ margin: '40px auto' }}>
            Please check back soon.
          </Typography>
        </div>
      );
    } else if (state.user !== undefined) {
      registerForm = <RegisterAgent data={data} user={state.user} />;
    } else {
      registerForm = <RegisterGuest data={data} />;
    }

    content = (
      <Container>
        <div
          style={{
            marginTop: 20,
            minHeight: 220,
            borderRadius: '10px 10px 0 0',
            backgroundColor: '#777',
            backgroundImage: data.BannerImagePath
              ? `url(${data.BannerImagePath})`
              : 'none',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <div
            style={{
              background: `linear-gradient(to right, #12121200, #12121299, #12121200)`,
              minHeight: 220,
              borderRadius: 10,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <div
                style={{
                  color: '#fff',
                  textShadow: '2px 2px rgba(0,0,0,0.6)',
                  fontSize: 64,
                  fontWeight: 'bold',
                  fontFamily: 'Oswald',
                  textTransform: 'uppercase',
                  letterSpacing: 1.1,
                }}
              >
                {data.Title}
              </div>

              {data.DateBegin && data.DateEnd ? (
                <div
                  style={{ color: '#fff', fontWeight: 'bold', fontSize: 22 }}
                >
                  {format(new Date(data.DateBegin), 'MMM d')} -{' '}
                  {format(new Date(data.DateEnd), 'PP')}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {registerForm}
      </Container>
    );
  } else {
    // Could not find event
    content = <EventNotFound />;
  }

  let routeTitle = '';
  if (loading) {
    routeTitle = '';
  } else if (data) {
    routeTitle = 'Register for ' + data.Title || '';
  }

  return (
    <RouteContainer
      routeTitle={routeTitle}
      noanimation
      loading={loading}
      hasError={hasError}
      hideFooter
    >
      {content}
    </RouteContainer>
  );
}
